import { AddCircleOutlineOutlined, Cancel, CloudDownload } from '@mui/icons-material'
import { Box, Button, CardContent, CircularProgress, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useMutation } from 'react-query'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { backendClient } from '../../../../interactors/clients/client'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { useAnimalStore } from '../../../../store/AnimalStore'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { getAnimalProfilePhotoSrc } from '../../../../utils/S3-links'
import { setOpacity } from '../../../../utils/setOpacity'
import { colors } from '../../../theme'

interface Props {
  animal: AnimalDto
}

export const AnimalOtherImagesCard: React.FC<Props> = ({ animal }) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: { 'image/*': [] },
    multiple: false,
  })
  const globalSnackbar = useGlobalSnackbarStore()
  const animalStore = useAnimalStore()
  const canEditAnimal = useCanEditAnimal()

  const [pictures, setPictures] = React.useState<string[]>(animal.images?.otherImagesKeys || [])

  const addImageMutation = useMutation(
    async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)
      return await backendClient.post(`animals/${animal.id}/images/other`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    },
    {
      onSuccess: (response) => {
        globalSnackbar.triggerSuccessMessage(`Votre photo a bien été ajoutée !`)
        const parts = response.data.split('/')
        setPictures((pictures) => [...pictures, parts[parts.length - 1]])
        animalStore.changeSelectedAnimal(animal.id)
      },
    }
  )

  const deleteImageMutation = useMutation(
    async (imageKey: string) => {
      return await backendClient.delete(`animals/${animal.id}/images/other/${imageKey}`)
    },
    {
      onSuccess: (_data, variables) => {
        globalSnackbar.triggerSuccessMessage(`Votre photo a bien été supprimée !`)
        setPictures((pictures) => pictures.filter((picture) => picture !== variables))
      },
    }
  )

  useEffect(() => {
    if (acceptedFiles[0] && (acceptedFiles[0].type === 'image/jpeg' || acceptedFiles[0].type === 'image/png')) {
      addImageMutation.mutate(acceptedFiles[0])
    }
  }, [acceptedFiles])

  const carouselSettings = {
    infinite: pictures && pictures.length > 1,
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 200,
    arrows: true,
    customPaging: () => <div className="ft-slick__dots--custom"></div>,
  }

  return (
    <CardContent sx={{ pt: 0, py: '10px !important' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          mb: 1,
        }}
      >
        <Typography variant="h5" color={colors.blue} fontWeight={700}>
          Autres photos ({pictures.length})
        </Typography>
      </Box>
      {addImageMutation.isLoading ? (
        <CircularProgress sx={{ pt: 2 }} />
      ) : pictures.length > 0 ? (
        <Box sx={{ position: 'relative', width: '100%' }}>
          {/* Carousel */}
          <Slider {...carouselSettings}>
            {pictures.map((imageKey) => (
              <div key={imageKey}>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    maxHeight: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '12px',
                  }}
                >
                  <img
                    src={getAnimalProfilePhotoSrc(imageKey)}
                    alt={imageKey}
                    style={{
                      width: '100%',
                      maxHeight: '300px',
                      objectFit: 'contain',
                      overflow: 'hidden',
                    }}
                  />
                  {canEditAnimal && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: colors.blueSky,
                        zIndex: 999,
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        deleteImageMutation.mutate(imageKey)
                      }}
                    >
                      <Cancel sx={{ fontSize: 32 }} />
                    </IconButton>
                  )}
                  <IconButton
                    sx={{
                      position: 'absolute',
                      bottom: 8,
                      right: 8,
                      color: 'primary.main',
                      zIndex: 999,
                    }}
                    component="a"
                    href={getAnimalProfilePhotoSrc(imageKey)}
                    download
                  >
                    <CloudDownload />
                  </IconButton>
                </div>
              </div>
            ))}
          </Slider>

          {/* Full-width button */}
          {canEditAnimal && (
            <Box sx={{ width: '100%', mt: 4, textAlign: 'center' }}>
              <Button
                {...getRootProps()}
                sx={{
                  textTransform: 'none',
                  color: colors.blue,
                  border: `1px solid ${colors.blue}`,
                  borderRadius: 2,
                  padding: 1,
                }}
                fullWidth
              >
                Ajouter une photo
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        // Add Photo (Full Width)
        canEditAnimal && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding={3}
            {...getRootProps({
              sx: {
                border: 3,
                borderRadius: 5,
                borderStyle: 'dashed',
                borderColor: isDragActive ? 'green' : colors.blue,
                backgroundColor: isDragActive ? 'lightgreen' : setOpacity(colors.geyser, 0.2),
                cursor: 'pointer',
                transition: 'all 0.3s',
              },
            })}
          >
            <input {...getInputProps()} />
            <AddCircleOutlineOutlined sx={{ color: colors.blue, fontSize: 64, mb: 1 }} />
            <Typography variant="h4" component="p" fontSize={20} textAlign="center">
              Ajouter une photo
            </Typography>
          </Box>
        )
      )}
    </CardContent>
  )
}
