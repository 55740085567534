export class TrimManager {
  private static MAX_LENGTH = 200

  static needTrim(value: string | undefined): boolean {
    return !!value && value?.length > this.MAX_LENGTH
  }

  static trimIfNeeded = (value: string | undefined): string => {
    return this.needTrim(value) ? `${value!.substring(0, this.MAX_LENGTH)}...` : value || '...'
  }
}
