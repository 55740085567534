import { Edit } from '@mui/icons-material'
import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Tooltip,
  FormControl,
  TextField,
} from '@mui/material'
import React from 'react'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { Controller } from 'react-hook-form'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { getBestCustomerTypeLabel, getReadableCustomerType } from '../../../domain/Customer/CustomerDisplay'
import { CustomerDto, AnimalDtoSpecies, CustomerDtoTypes } from '../../../interactors/gen/backendClient'
import { ControlledMultipleAutoComplete } from '../../common/ControlledMultipleAutoComplete'
import { spacingItem } from '../../theme'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'
import { omit } from 'lodash'

interface Props {
  association: CustomerDto
  onSubmit: (data: Partial<CustomerDto>) => Promise<void>
}

interface FormParams {
  types: CustomerDto['types']
  SIRETNumber: CustomerDto['SIRETNumber']
  RNANumber: CustomerDto['RNANumber']
  address: CustomerDto['address']
  streetNumber: CustomerDto['streetNumber']
  postalCode: CustomerDto['postalCode']
  city: CustomerDto['city']
  email: CustomerDto['email']
  phoneNumber: CustomerDto['phoneNumber']
  species: AnimalDtoSpecies[]
}

export const EditSiteGeneralInfoModal: React.FC<Props> = ({ association, onSubmit }) => {
  const dialogOpenedState = useDialogOpened()

  const processSubmit = (data: FormParams) => {
    onSubmit({ ...omit(data, 'species'), preferences: { species: data.species } })
    return dialogOpenedState.closeDialog()
  }

  const getDefaultValues = (customer: CustomerDto): FormParams => ({
    types: customer?.types,
    SIRETNumber: customer?.SIRETNumber,
    RNANumber: customer?.RNANumber,
    address: customer?.address,
    streetNumber: customer?.streetNumber,
    postalCode: customer?.postalCode,
    city: customer?.city,
    email: customer?.email,
    phoneNumber: customer?.phoneNumber,
    species: customer?.preferences?.species,
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    register,
  } = useFormExtended(association, getDefaultValues)

  const watchSiret = watch('SIRETNumber')
  const watchRna = watch('RNANumber')

  const isSiretOrRnaFilled = (): boolean => {
    if (watchSiret || watchRna) {
      return true
    }
    return false
  }

  return (
    <>
      <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
        <Tooltip title={`Editer ${getBestCustomerTypeLabel(association?.types, { the: true })}`}>
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>{`Editer ${getBestCustomerTypeLabel(association?.types, { the: true })}`}</DialogTitle>

        <form
          onSubmit={handleSubmit(processSubmit)}
          style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}
        >
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <Box display="flex" width="100%" alignItems="center">
                <ControlledMultipleAutoComplete
                  control={control}
                  fieldName="types"
                  label="Type d'organisation"
                  error={errors.address}
                  options={Object.values(CustomerDtoTypes).map((type: CustomerDtoTypes) => ({
                    label: getReadableCustomerType(type),
                    value: type,
                  }))}
                  requiredRule=""
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Numéro de siret"
                  type="text"
                  required={!isSiretOrRnaFilled()}
                  fullWidth
                  {...register('SIRETNumber', {
                    required: !isSiretOrRnaFilled() ? 'Veuillez remplir le numéro SIRET ou le numéro RNA' : '',
                  })}
                  error={!!errors.SIRETNumber}
                  helperText={errors.SIRETNumber?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Numéro de RNA"
                  type="text"
                  required={!isSiretOrRnaFilled()}
                  fullWidth
                  {...register('RNANumber')}
                  error={!!errors.RNANumber}
                  helperText={errors.RNANumber?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Numéro de rue"
                  type="text"
                  fullWidth
                  {...register('streetNumber')}
                  error={!!errors.streetNumber}
                  helperText={errors.streetNumber?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Adresse de domiciliation"
                  type="text"
                  required
                  fullWidth
                  {...register('address', { required: "L'adresse est requise" })}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Code postal"
                  type="text"
                  required
                  fullWidth
                  {...register('postalCode', { required: 'Le code postal est requis' })}
                  error={!!errors.postalCode}
                  helperText={errors.postalCode?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  label="Ville"
                  type="text"
                  required
                  fullWidth
                  {...register('city', { required: 'La ville est requise' })}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  {...register('email', {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "L'addresse n'est pas valide.",
                    },
                  })}
                  // error={!!errors.email}
                  // helperText={errors.email?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      validate: (value: string | undefined) => {
                        console.log(value)
                        return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                          ? true
                          : 'Tel is invalid'
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <MuiTelInput
                        {...field}
                        label="Numero de Téléphone"
                        defaultCountry="FR"
                        helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                        error={!!fieldState.error}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box display="flex" width="100%" alignItems="center" sx={spacingItem}>
                <ControlledMultipleAutoComplete
                  control={control}
                  fieldName="species"
                  label="Espèces"
                  error={errors.address}
                  options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                    label: getReadableSpecies(status, { withEmoji: false }),
                    value: status,
                  }))}
                  requiredRule=""
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <MemberCardSaveButton isOwnAccount={false} />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
