import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { Edit } from '@mui/icons-material'

import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { LoadingButton } from '@mui/lab'
import { AxiosError } from 'axios'
import { readableAxiosError } from '../../../utils/axios'
import { spacingItem } from '../../theme'

import { GENERIC_DISPLAY } from '../../../utils/genericDisplay'
import { useCanEditAnimal } from '../../../store/useCanEditAnimal'
import { AnimalDto, AnimalDtoHairLength } from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { getReadableAnimalHairLength, getReadableAnimalFieldLabel } from '../../../domain/Animal/AnimalDisplay'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { useEditAnimalMutation } from '../../../store/useEditAnimalMutation'

interface Props {
  title: string
  animal: AnimalDto
  renderButton?: (onClick: () => void) => any
}

interface FormParams {
  color: string
  hairLength?: AnimalDtoHairLength
  description?: string
}

/**
 * For now, supposed to be used in the Animal Page, update support if you want to use outside
 */
export const EditorPromptWithFields: FC<Props> = ({ title, renderButton, animal }) => {
  const canEditAnimal = useCanEditAnimal()
  const editAnimalMutation = useEditAnimalMutation({})

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dialogOpenedState = useDialogOpened()
  const [serverError, setServerError] = useState('')

  const onSave = async (data: FormParams) => {
    setIsLoading(true)

    await editAnimalMutation
      .mutateAsync({
        ...animal,
        color: data.color,
        hairLength: data.hairLength,
        description: data.description,
      })
      .then(async () => {
        setIsLoading(false)
        dialogOpenedState.closeDialog()
      })
      .catch((error: Error | AxiosError) => {
        setServerError(readableAxiosError(error).join(' '))
        setIsLoading(false)
      })
  }

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    color: animal?.color || '',
    hairLength: animal?.hairLength,
    description: animal?.description || '',
  })

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormExtended(animal, getDefaultValues)

  const description = watch('description')
  const onEditDescription = (newDescription: string) => {
    setValue('description', newDescription)
  }
  return (
    <>
      {renderButton ? (
        renderButton(dialogOpenedState.openDialog)
      ) : (
        <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
          <Tooltip title="Editer">
            <>
              <Edit />
            </>
          </Tooltip>
        </IconButton>
      )}

      <Dialog
        maxWidth="xl"
        sx={{ marginX: 'auto' }}
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div>
            <ReactQuill theme="snow" value={description} onChange={onEditDescription} />
          </div>

          <form id="editor-form" onSubmit={handleSubmit(onSave)}>
            <ControlledSelectField
              sx={spacingItem}
              control={control}
              error={errors.hairLength}
              fieldName="hairLength"
              label="Type de poils"
              options={Object.values(AnimalDtoHairLength).map((value: AnimalDtoHairLength) => ({
                label: getReadableAnimalHairLength(value),
                value: value,
              }))}
              requiredRule={undefined}
            />

            {(['color'] as const).map((fieldName) => (
              <TextField
                id={`${fieldName}-input`}
                key={fieldName}
                label={getReadableAnimalFieldLabel(fieldName)}
                type="text"
                fullWidth
                {...register(fieldName)}
                sx={spacingItem}
              />
            ))}
          </form>

          {serverError && (
            <Box sx={spacingItem}>
              <Typography color="error">{serverError}</Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Tooltip title={GENERIC_DISPLAY.doNotHavePermissionAsReader}>
            <Box component="span" sx={{ width: '100%' }}>
              <LoadingButton
                variant="contained"
                sx={{ width: '100%' }}
                loading={isLoading}
                type="submit"
                disabled={!canEditAnimal}
                form="editor-form"
              >
                Mettre à jour
              </LoadingButton>
            </Box>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  )
}
