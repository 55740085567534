import { Box, Card, CardContent, CardHeader, Grid, SxProps } from '@mui/material'
import React from 'react'
import { isAdmin } from '../../../domain/Account/isAdmin'
import { getReadableCustomerType } from '../../../domain/Customer/CustomerDisplay'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useFetchOrRefreshMembersOnMount } from '../../../store/useFetchOrRefreshMembersOnMount'
import { ReadOnlyChip } from '../../common/ReadOnlyChip'
import { ReadOnlyItem } from '../../common/ReadOnlyItem'
import { EditSiteGeneralInfoModal } from './EditSiteGeneralInfosModal'
import { ReadOnlyChipsWithOverflow } from '../../common/ReadOnlyChips'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { colors } from '../../theme'

interface Props {
  association: CustomerDto
  onSubmit: (data: Partial<CustomerDto>) => Promise<void>
  sx?: SxProps
}

export const SiteGeneralInfoCard: React.FC<Props> = ({ association, onSubmit, sx }) => {
  const accountsStore = useAccountsStore()

  const canEdit = accountsStore.connectedAccount ? isAdmin(accountsStore.connectedAccount) : false

  useFetchOrRefreshMembersOnMount()

  const defaultItemProps = { xs: 6, sm: 3, item: true, marginBottom: 2 }
  const addressItemProps = { xs: 12, sm: 6, item: true, marginBottom: 2 }
  const smallItemProps = defaultItemProps

  if (!association) return null

  return (
    <Card sx={sx}>
      <CardHeader
        title={`Informations générales`}
        action={canEdit && <EditSiteGeneralInfoModal association={association} onSubmit={onSubmit} />}
        sx={{ pb: 0 }}
      />
      <CardContent>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid {...smallItemProps}>
                <ReadOnlyChip
                  label={`Type d'organisation`}
                  value={
                    association.types && association.types.length > 0
                      ? getReadableCustomerType(association.types[0])
                      : '..'
                  }
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem
                  label={`Numéro de SIRET`}
                  value={association.SIRETNumber ? `${association.SIRETNumber}` : '..'}
                />
              </Grid>

              <Grid {...smallItemProps}>
                <ReadOnlyItem label={`Numéro de RNA`} value={association.RNANumber ? association.RNANumber : '..'} />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyChipsWithOverflow
                  label={`Animaux pris en charge`}
                  values={
                    association.preferences?.species
                      ? association.preferences.species.map((s) => getReadableSpecies(s))
                      : ['..']
                  }
                  chipColor={colors.blueSky}
                  forceChipTextWhite={true}
                  maxVisible={2}
                />
              </Grid>

              <Grid {...addressItemProps}>
                <ReadOnlyItem
                  label="Adresse de domiciliation"
                  value={`${association.streetNumber ?? ''} ${association.address ?? ''} ${
                    association.postalCode ?? ''
                  } ${association.city ?? ''}`}
                />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem label={`Téléphone`} value={association.phoneNumber ? association.phoneNumber : '..'} />
              </Grid>

              <Grid {...defaultItemProps}>
                <ReadOnlyItem label={`Adresse email`} value={association.email ? association.email : '..'} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
