import { Save } from '@mui/icons-material'
import { Button, useMediaQuery } from '@mui/material'
import { Tooltip } from '@mui/material'
import { FC } from 'react'
import { useAccountsStore } from '../../store/AccountsStore'
import { GENERIC_DISPLAY } from '../../utils/genericDisplay'

interface MemberCardSaveButtonProps {
  isOwnAccount: boolean
  disabled?: boolean
}

export const MemberCardSaveButton: FC<MemberCardSaveButtonProps> = ({ isOwnAccount, disabled }) => {
  const accountsStore = useAccountsStore()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const canEdit = accountsStore.isAdmin() || isOwnAccount

  return (
    <Tooltip title={canEdit ? '' : GENERIC_DISPLAY.doNotHavePermissionAsReader}>
      <span>
        {' '}
        <Button
          color="primary"
          variant="outlined"
          type="submit"
          disabled={!canEdit || disabled}
          sx={{ textTransform: 'none', width: isMobile ? '100%' : 'auto' }}
        >
          {!isMobile && <Save sx={{ marginRight: 1 }} />}
          Mettre à jour
        </Button>
      </span>
    </Tooltip>
  )
}
