import { Box } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'
import { useDashboardLoaderStore } from '../store/DashboardLoaderStore'
import { CircularProgressPanel } from './common/CircularProgressPanel'
import { ResponsiveAppBar } from './ResponsiveAppBar'
import { CustomersClient } from '../interactors/clients/CustomerClient'
import { useAccountsStore } from '../store/AccountsStore'
import { AccountDtoPermission, CustomerDto } from '../interactors/gen/backendClient'
import { UserNumberAlertBanner } from './UserNumberAlertBanner'

export const DashboardStructure: FC = () => {
  const { isLoading } = useDashboardLoaderStore()

  const accountsStore = useAccountsStore()

  const [customer, setCustomer] = React.useState<CustomerDto | null>(null)

  useEffect(() => {
    const fetchCustomer = async () => {
      const customer = await CustomersClient.getCurrentCustomer()
      accountsStore.changeConnectedCustomer(customer)
      setCustomer(customer)
    }

    fetchCustomer()
  }, [accountsStore.changeConnectedAccount])

  const isSubscribed = customer?.subscription?.status === 'active'

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          maxHeight: '100vh',
          overflowY: 'hidden',
        }}
      >
        <Box sx={{ position: 'relative', maxHeight: '100%', height: '100%', overflowY: 'hidden' }}>
          <ResponsiveAppBar customerTypes={customer?.types} />

          <Box className="scrollable-content" sx={{ maxHeight: '100%', height: '100%', overflowY: 'auto' }}>
            {!isSubscribed &&
              accountsStore.members.filter((member) => member.permission !== AccountDtoPermission.Silent).length >
                7 && <UserNumberAlertBanner isMembersFull={accountsStore.members.length >= 10} />}
            {isLoading && <CircularProgressPanel />}
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>
    </>
  )
}
