export const PATHS = {
  association: { absolute: '/association', relative: 'association' },

  carte: { absolute: '/la-carte', relative: 'la-carte' },

  ajouterDonateur: { absolute: '/association/ajouter-donateur', relative: 'ajouter-donateur' },

  animals: { absolute: '/animaux', relative: 'animaux' },
  ajouterAnimal: { absolute: '/animaux/ajouter-animal', relative: 'ajouter-animal' },
  animalDetails: { absolute: '/animaux/:animalId', relative: ':animalId' },
  multiEdition: { absolute: '/animaux/multi-edition', relative: 'multi-edition' },

  suiviBenevole: { absolute: '/suivi-benevole', relative: 'suivi-benevole' },
  ajouterBenevole: { absolute: '/suivi-benevole/ajouter-benevole', relative: 'ajouter-benevole' },
  accountDetails: { absolute: '/suivi-benevole/:accountId', relative: ':accountId' },

  suiviVeterinaire: { absolute: '/suivi-veterinaire', relative: 'suivi-veterinaire' },
  ajouterVeterinaire: { absolute: '/suivi-veterinaire/ajouter-veterinaire', relative: 'ajouter-veterinaire' },
  veterinaryDetails: { absolute: '/suivi-veterinaire/:accountId', relative: ':accountId' },

  suiviAdoptant: { absolute: '/suivi-adoptant', relative: 'suivi-adoptant' },
  ajouterAdoptant: { absolute: '/suivi-adoptant/ajouter-adoptant', relative: 'ajouter-adoptant' },
  assignerAnimal: { absolute: '/suivi-adoptant/assigner-animal', relative: 'assigner-animal' },
  adoptantDetails: { absolute: '/suivi-adoptant/:accountId', relative: ':adopterId' },

  login: { absolute: '/login', relative: 'login' },
}
