import React from 'react'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { SiteGeneralInfoCard } from './SiteGeneralInfosCard'
import { AxiosError } from 'axios'
import { UseMutationResult } from 'react-query'
import { AssociationSiteDescription } from './AssociationSiteDescription'
import { ContentCopy } from '@mui/icons-material'
import { Box, Typography, Button, Container, Paper } from '@mui/material'
import { AssociationAdoptableAnimals } from './AssociationAdoptableAnimals'
import { colors } from '../../theme'

interface Props {
  association: CustomerDto
  editAssociationMutation: UseMutationResult<CustomerDto, Error | AxiosError<unknown, any>, CustomerDto, unknown>
}

export const AssociationSiteTab: React.FC<Props> = ({ association, editAssociationMutation }) => {
  const onSubmit = async (data: Partial<CustomerDto>) => {
    const newData = {
      ...association,
      ...data,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
    }
    console.log('newData', newData)
    editAssociationMutation.mutate(newData)
  }
  if (!association) return null

  const link = `https://petso.fr/associations/${association.id}`

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)
    alert('Lien de votre page copié dans le presse-papiers.')
  }
  return (
    <Container maxWidth="lg" sx={{ mb: 10, p: '0 !important' }}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Copy Link */}
        <Box
          sx={{
            backgroundColor: '#f9f9f9',
            padding: 2,
            borderRadius: 2,
            border: '1px solid #ddd',
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ fontSize: '0.875rem', color: '#333' }}>
            <span style={{ color: colors.blue }}>
              L’ensemble de ces information sont visibles sur votre page petso :
            </span>{' '}
            <a href={link} target="_blank" rel="noreferrer">
              {link}
            </a>
          </Typography>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCopyLink}
            sx={{ textTransform: 'none', padding: 0, minWidth: 0 }}
          >
            <ContentCopy sx={{ fontSize: '1rem', ml: 0.5 }} />
          </Button>
        </Box>
        <SiteGeneralInfoCard association={association} onSubmit={onSubmit} />
        <AssociationSiteDescription association={association} onSubmit={onSubmit} />
        <Box sx={{ mt: 2 }}>
          <AssociationAdoptableAnimals />
        </Box>
      </Paper>
    </Container>
  )
}
