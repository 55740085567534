import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { getReadableCustomerTemplateType } from '../../../domain/Customer/CustomerDisplay'
import { CustomerPdfTemplateZoneDto, CustomerTemplateDtoType } from '../../../interactors/gen/backendClient'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { Add } from '@mui/icons-material'

interface TemplateForm {
  name: string
  type: CustomerTemplateDtoType
  file: File | null
}

export const AddPdfTemplateModal: React.FC = () => {
  const location = useLocation()
  const [open, setOpen] = useState(location.search.includes('new=true')) // Open modal if URL contains 'new' query param
  const [showTutorial, setShowTutorial] = useState(true)
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TemplateForm>({
    defaultValues: { name: '', type: undefined, file: null },
  })

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setValue('file', acceptedFiles[0])
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
    multiple: false,
  })

  const onSubmit = (data: TemplateForm) => {
    // Define the template object
    const template = {
      name: data.name,
      type: data.type,
      zones: [] as CustomerPdfTemplateZoneDto[],
      file: data.file,
    }
    navigate('./zone', { state: { template } }) // Redirect to edit zones page with template state
    setOpen(false) // Close modal after submit
  }

  return (
    <>
      {/* Add New PDF Template Button */}
      <Fab color="primary" variant="extended" onClick={() => setOpen(true)}>
        <Add sx={{ mr: 1 }} />
        Ajouter un modèle PDF
      </Fab>

      {/* Modal */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Ajouter un nouveau modèle PDF</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
            {/* Template Name */}
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Nom du modèle requis' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nom du modèle"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              )}
            />

            {/* Template Type */}
            <ControlledSelectField<TemplateForm>
              control={control}
              fieldName="type"
              label="Type de modèle"
              requiredRule="Type de modèle requis"
              error={undefined}
              options={Object.values(CustomerTemplateDtoType).map((type) => ({
                value: type,
                label: getReadableCustomerTemplateType(type),
              }))}
            />

            {/* File Upload */}
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed #aaa',
                borderRadius: 1,
                p: 2,
                textAlign: 'center',
                mt: 2,
                cursor: 'pointer',
                backgroundColor: isDragActive ? '#f0f0f0' : 'transparent',
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1">
                {watch('file')
                  ? `Fichier sélectionné: ${watch('file')?.name}`
                  : 'Glissez et déposez un fichier PDF ici, ou cliquez pour sélectionner'}
              </Typography>
            </Box>

            {/* YouTube Tutorial with Close Button */}
            {showTutorial && (
              <Box
                sx={{
                  mt: 4,
                  p: 2,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  backgroundColor: '#f9f9f9',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  position: 'relative',
                }}
              >
                {/* Close Button */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowTutorial(false)}
                >
                  <Typography variant="body2" fontWeight="bold" color="text.secondary">
                    ✕
                  </Typography>
                </Box>

                <Box
                  component="img"
                  src="https://img.youtube.com/vi/1oO1jA-VLNo/0.jpg"
                  alt="YouTube tutorial"
                  sx={{
                    width: 80,
                    height: 45,
                    borderRadius: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open('https://www.youtube.com/watch?v=1oO1jA-VLNo', '_blank')}
                />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Regardez le tutoriel
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Cliquez pour découvrir comment créer un modèle PDF avec des variables et des zones.
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ ml: 'auto' }}
                  onClick={() => window.open('https://www.youtube.com/watch?v=1oO1jA-VLNo', '_blank')}
                >
                  Voir
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>

        {/* Modal Actions */}
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
