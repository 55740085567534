import { LoadingButton } from '@mui/lab'
import { Box, Container, Divider, Grid, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { fullName } from '../../domain/Account/AccountDisplay'
import {
  getColorForAnimalVaccineStatus,
  getReadableAnimalBreedTitle,
  getReadableAnimalFieldLabel,
  getReadableAnimalHairLength,
  getReadableAnimalHealthPathology,
  getReadableAnimalVaccineStatus,
  getReadableSterilizedForFerrets,
  getReadableTakeOverOrigin,
  getReadableTakeOverReason,
} from '../../domain/Animal/AnimalDisplay'
import {
  CatPathologies,
  DogPathologies,
  FerretPathologies,
  GlobalPathologies,
} from '../../domain/Animal/AnimalPathologies'
import { catBreeds, dogBreeds } from '../../domain/Animal/Breeds'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import {
  AccountDto,
  AccountDtoPermission,
  AccountDtoRoles,
  AnimalAdoptionDtoStatus,
  AnimalBreedDtoBreedTitle,
  AnimalDtoHairLength,
  AnimalDtoSpecies,
  AnimalHealthDtoHasEuropeanPassport,
  AnimalHealthDtoPathologies,
  AnimalHealthDtoSterilizedForFerrets,
  AnimalHealthDtoSterilizedOrNeutered,
  AnimalTakeOverDtoOrigin,
  AnimalTakeOverDtoReason,
  CreateAnimalBodyDtoVaccinationStatus,
} from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useFetchOrRefreshMembersOnMount } from '../../store/useFetchOrRefreshMembersOnMount'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { getColorForBooleanOrUnknown, getReadableBooleanOrUnknown } from '../../utils/getReadableBooleanOrUnknown'
import { ColoredChipSelectField } from '../common/ColoredChipSelectField'
import { AnimalsAutocomplete } from '../common/ControlledAnimalsWithDetailsAutocomplete'
import { ControlledAutoCompleteWithCustomValue } from '../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../common/ControlledDateField'
import { ControlledMultipleAutoComplete } from '../common/ControlledMultipleAutoComplete'
import { ControlledMultipleFreeSoloAutoCompleteWithCategories } from '../common/ControlledMultipleFreeSoloAutocompleteWithCategories'
import { ControlledSelectField } from '../common/ControlledSelectField'

type FormParams = {
  breed?: string
  breedTitle?: AnimalBreedDtoBreedTitle
  fatherBreed?: string
  motherBreed?: string
  color?: string
  hair?: string
  characteristics?: Array<string>
  litterNumber?: string
  birthdayDate?: string
  isApproximateBirthdayDate?: number
  hairLength?: AnimalDtoHairLength
  memberInChargeAccountId?: string
  animalLocation?: string
  veterinaryChargeAccountId?: string
  sterilizedOrNeutered?: string
  pathologies?: AnimalHealthDtoPathologies[]
  hasEuropeanPassport?: AnimalHealthDtoHasEuropeanPassport
  sterilizedOrNeuteredDate?: string
  sterilizedForFerrets?: string
  vaccinationStatus?: string
  takeOverAt?: string
  addressOfOrigin?: string
  cityOfOrigin?: string
  reason?: AnimalTakeOverDtoReason
  origin?: AnimalTakeOverDtoOrigin
}

export type SmallAnimals = {
  label: string
  value: string
  species: AnimalDtoSpecies
  adoptionStatus: AnimalAdoptionDtoStatus
}

export const AnimalBulkEdit: React.FC = () => {
  const location = useLocation()
  const selectedAnimals = location.state?.selectedAnimals as string
  const [animals, setAnimals] = React.useState<SmallAnimals[]>(
    selectedAnimals.length > 0 ? JSON.parse(selectedAnimals) : []
  )
  const [loading, setLoading] = React.useState(false)
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()
  const globalSnackbarStore = useGlobalSnackbarStore()

  useFetchOrRefreshMembersOnMount()

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FormParams>()

  const connectedAccountId = accountsStore.connectedAccount?.id
  const connectedPermission = accountsStore.connectedAccount?.permission
  const isEditor = connectedPermission?.includes(AccountDtoPermission.Editor)

  const options = accountsStore.members
  const optionsForMemberInCharge = options.filter((option) => {
    return option.roles.includes(AccountDtoRoles.Member)
  })

  if (connectedAccountId) {
    options.sort((x, y) => {
      return x.id === connectedAccountId ? -1 : y.id == connectedAccountId ? 1 : 0
    })
  }

  const getOptionLabel = (option: AccountDto): string => {
    let optionLabel = fullName(option)
    if (option.id === accountsStore.connectedAccount?.id) {
      optionLabel = `Moi (${optionLabel})`
    }

    if (option.isDeleted) {
      optionLabel = `Compte supprimé (${optionLabel})`
    }

    return optionLabel
  }

  const allAnimalsAreSameSpecies = animals.every((animal) => animal.species === animals[0].species)
  const species = animals.length > 0 && allAnimalsAreSameSpecies ? animals[0].species : undefined
  const needBreedsDropdown = species === AnimalDtoSpecies.Cat || species === AnimalDtoSpecies.Dog

  const breedOptions =
    species === 'cat'
      ? catBreeds.map((breed) => ({ label: breed, value: breed }))
      : dogBreeds.map((breed) => ({ label: breed, value: breed }))

  const watchBreedTitle = watch('breedTitle')

  const allPathologies = Object.values(AnimalHealthDtoPathologies)

  function filterPathologiesForSpecies(animalSpecies: AnimalDtoSpecies | undefined): AnimalHealthDtoPathologies[] {
    let filterOutPathologies: Partial<AnimalHealthDtoPathologies[]> = []
    switch (animalSpecies) {
      case AnimalDtoSpecies.Dog:
        filterOutPathologies = CatPathologies.concat(FerretPathologies).filter(
          (pathology) => !DogPathologies.includes(pathology)
        )
        break
      case AnimalDtoSpecies.Cat:
        filterOutPathologies = DogPathologies.concat(FerretPathologies).filter(
          (pathology) => !CatPathologies.includes(pathology)
        )
        break
      default:
        filterOutPathologies = DogPathologies.concat(CatPathologies)
          .concat(FerretPathologies)
          .filter((pathology) => !GlobalPathologies.includes(pathology))
        break
    }

    // Include all operation types except those specifically filtered out for the species
    return allPathologies.filter((type) => !filterOutPathologies.includes(type))
  }

  const speciesPathologies = filterPathologiesForSpecies(species)

  const onSubmit = async (data: FormParams) => {
    setLoading(true)
    console.log('Animals to Edit:', animals)

    const filteredData = _.omitBy(data, (value) => value === null || value === undefined || value === '')

    if (filteredData.sterilizedForFerrets) {
      switch (filteredData.sterilizedForFerrets as AnimalHealthDtoSterilizedForFerrets) {
        case 'sterilized':
          filteredData.sterilizedOrNeutered = 'true'
          break
        case 'not-sterilized':
          filteredData.sterilizedOrNeutered = 'false'
          break
        case 'unknown':
          filteredData.sterilizedOrNeutered = 'unknown'
          break
        case 'implanted':
          filteredData.sterilizedOrNeutered = 'true'
          break
        default:
          break
      }
    }

    const updates = {
      breedData: {
        breed: filteredData.breed,
        breedTitle: filteredData.breedTitle,
        fatherBreed: filteredData.fatherBreed,
        motherBreed: filteredData.motherBreed,
        litterNumber: filteredData.litterNumber,
      },
      color: filteredData.color,
      hairType: filteredData.hair,
      hairLength: filteredData.hairLength,
      characteristics: filteredData.characteristics,
      birthday: {
        date: filteredData.birthdayDate || undefined,
        isApproximate: filteredData.isApproximateBirthdayDate,
      },
      responsibilities: {
        memberInChargeAccountId: filteredData.memberInChargeAccountId,
        veterinaryChargeAccountId: filteredData.veterinaryChargeAccountId,
      },
      adoption: {
        status: filteredData.adoptionStatus,
      },
      health: {
        pathologies: filteredData.pathologies,
        hasEuropeanPassport: filteredData.hasEuropeanPassport,
        sterilizedOrNeutered: filteredData.sterilizedOrNeutered,
        sterilizedForFerrets: filteredData.sterilizedForFerrets,
      },
      vaccination: {
        vaccinationStatus: filteredData.vaccinationStatus,
      },
      takeOver: {
        takeOverAt: filteredData.takeOverAt,
        addressOfOrigin: filteredData.addressOfOrigin,
        cityOfOrigin: filteredData.cityOfOrigin,
        reason: filteredData.reason,
        origin: filteredData.origin,
      },
    }

    const filteredUpdates = _.omitBy(updates, (value) => value === null || value === undefined || value === '')

    console.log('Filtered Updates:', filteredUpdates)
    const numberOfEdited = await AnimalsClient.editManyAnimals(
      animals.map((animal) => animal.value),
      filteredUpdates
    )
    globalSnackbarStore.triggerSuccessMessage(`Modifications enregistrées pour ${numberOfEdited.updatedCount} animaux`)
    navigate('/animaux')
    setLoading(false)
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        mb: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 4,
        backgroundColor: '#f9fafb',
        borderRadius: 2,
        padding: 4,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        sx={{
          color: '#1e88e5',
          textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
          marginBottom: 4,
        }}
      >
        🐾 Modifier plusieurs animaux 🐾
      </Typography>

      {/* Animal Selection */}
      <Box
        sx={{
          backgroundColor: '#ffffff',
          padding: 3,
          borderRadius: 2,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#424242' }}>
          Animaux à modifier
        </Typography>
        <AnimalsAutocomplete
          label="Rechercher et sélectionner des animaux"
          selectedAnimals={animals}
          setSelectedAnimals={setAnimals}
        />
      </Box>

      {/* Form Section */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          backgroundColor: '#ffffff',
          padding: '24px',
          borderRadius: '8px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Grid container spacing={1}>
          {/* General Info Section */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                color: '#1e88e5',
                fontWeight: 'bold',
              }}
            >
              🐕 Informations Générales
            </Typography>
            <Divider sx={{ mb: 3, borderColor: '#e0e0e0' }} />
          </Grid>

          {/* Dynamic Breed Information */}
          {species && (
            <>
              <Grid item sm={4} xs={12}>
                <ControlledSelectField
                  control={control}
                  error={errors.breedTitle}
                  fieldName="breedTitle"
                  label="Titre"
                  options={Object.values(AnimalBreedDtoBreedTitle).map((field: AnimalBreedDtoBreedTitle) => ({
                    label: getReadableAnimalBreedTitle(field),
                    value: field,
                  }))}
                  requiredRule={undefined}
                />
              </Grid>
              {watchBreedTitle !== AnimalBreedDtoBreedTitle.MixedBreed && (
                <Grid item sm={8} xs={12}>
                  {needBreedsDropdown ? (
                    <ControlledAutoCompleteWithCustomValue
                      control={control}
                      fieldName="breed"
                      error={errors.breed}
                      aria-label="Race"
                      label="Race"
                      requiredRule={undefined}
                      options={breedOptions}
                      defaultValue=""
                    />
                  ) : (
                    <TextField
                      id="breed-input"
                      label={getReadableAnimalBreedTitle(watchBreedTitle as AnimalBreedDtoBreedTitle) || 'Race'}
                      type="text"
                      fullWidth
                      {...register('breed')}
                      error={!!errors.breed}
                    />
                  )}
                </Grid>
              )}
              {watchBreedTitle === AnimalBreedDtoBreedTitle.MixedBreed && (
                <>
                  <Grid item sm={4} xs={12}>
                    {needBreedsDropdown ? (
                      <ControlledAutoCompleteWithCustomValue
                        control={control}
                        fieldName="fatherBreed"
                        error={errors.fatherBreed}
                        aria-label="Race du père"
                        label="Race du père"
                        requiredRule={undefined}
                        options={breedOptions}
                        defaultValue=""
                      />
                    ) : (
                      <TextField
                        id="father-breed-input"
                        label="Race du père"
                        type="text"
                        fullWidth
                        {...register('fatherBreed')}
                        error={!!errors.fatherBreed}
                      />
                    )}
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    {needBreedsDropdown ? (
                      <ControlledAutoCompleteWithCustomValue
                        control={control}
                        fieldName="motherBreed"
                        error={errors.motherBreed}
                        aria-label="Race de la mère"
                        label="Race de la mère"
                        requiredRule={undefined}
                        options={breedOptions}
                        defaultValue=""
                      />
                    ) : (
                      <TextField
                        id="mother-breed-input"
                        label="Race de la mère"
                        type="text"
                        fullWidth
                        {...register('motherBreed')}
                        error={!!errors.motherBreed}
                      />
                    )}
                  </Grid>
                </>
              )}
              <Grid item sm={4} xs={12}>
                <TextField
                  id="litter-number-input"
                  label="N° de portée"
                  type="text"
                  fullWidth
                  {...register('litterNumber')}
                  error={!!errors.litterNumber}
                />
              </Grid>
            </>
          )}

          <Grid item sm={6} xs={12}>
            <ControlledSelectField
              control={control}
              error={errors.hairLength}
              fieldName="hairLength"
              label="Type de poils"
              options={Object.values(AnimalDtoHairLength).map((value: AnimalDtoHairLength) => ({
                label: getReadableAnimalHairLength(value),
                value: value,
              }))}
              requiredRule={undefined}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {(['color'] as const).map((fieldName) => (
              <TextField
                id={`${fieldName}-input`}
                key={fieldName}
                label={getReadableAnimalFieldLabel(fieldName)}
                type="text"
                fullWidth
                {...register(fieldName)}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <ControlledMultipleFreeSoloAutoCompleteWithCategories
              options={characteristicsOptions}
              freeSolo={true}
              control={control}
              error={undefined}
              fieldName="characteristics"
              label="Caractéristiques"
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledDateField
              control={control}
              validate={(value) => isValidPastDateCoherence(value) || !value}
              error={errors.birthdayDate}
              fieldName={'birthdayDate'}
              label="Date de Naissance"
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledSelectField
              control={control}
              error={errors.isApproximateBirthdayDate}
              fieldName="isApproximateBirthdayDate"
              label="Est-elle exacte ?"
              options={[
                { label: 'Non', value: 1 },
                { label: 'Oui', value: 2 },
              ]}
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ControlledAutoCompleteWithCustomValue
              control={control}
              fieldName={'memberInChargeAccountId'}
              defaultValue=""
              options={[
                ...(accountsStore.connectedAccount &&
                accountsStore.connectedAccount.roles.includes(AccountDtoRoles.Member)
                  ? [
                      {
                        value: accountsStore.connectedAccount?.id,
                        label: getOptionLabel(accountsStore.connectedAccount),
                      },
                    ]
                  : []),
                ...optionsForMemberInCharge
                  .filter((option) => option.id !== connectedAccountId)
                  .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                  .map((option) => ({
                    label: getOptionLabel(option),
                    value: option.id,
                  })),
              ]}
              label="Référent en charge"
              removeCustomValue={isEditor}
              error={undefined}
              requiredRule={undefined}
              highlight
              disabled={(
                [AccountDtoPermission.Read, AccountDtoPermission.Silent] as Array<AccountDtoPermission>
              ).includes(connectedPermission!)}
            />
          </Grid>

          {/* Health Section */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                color: '#1e88e5',
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              🩺 Informations Santé
            </Typography>
            <Divider sx={{ mb: 3, borderColor: '#e0e0e0' }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ControlledAutoCompleteWithCustomValue
              control={control}
              fieldName={'veterinaryChargeAccountId'}
              defaultValue=""
              options={[
                ...(accountsStore.connectedAccount &&
                accountsStore.connectedAccount?.roles.includes(AccountDtoRoles.Veterinary)
                  ? [
                      {
                        value: accountsStore.connectedAccount?.id,
                        label: getOptionLabel(accountsStore.connectedAccount),
                      },
                    ]
                  : []),
                ...options
                  .filter((option) => option.roles.includes(AccountDtoRoles.Veterinary))
                  .filter((option) => option.id !== accountsStore.connectedAccount?.id)
                  .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                  .map((option) => ({
                    label: getOptionLabel(option),
                    value: option.id,
                  })),
              ]}
              label="Vétérinaire en charge"
              error={undefined}
              requiredRule={undefined}
              highlight
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ControlledMultipleAutoComplete
              control={control}
              fieldName="pathologies"
              label="Pathologies"
              options={speciesPathologies
                .sort((a, b) => getReadableAnimalHealthPathology(a).localeCompare(getReadableAnimalHealthPathology(b)))
                .map((value: AnimalHealthDtoPathologies) => ({
                  label: getReadableAnimalHealthPathology(value),
                  value: value,
                }))}
              error={undefined}
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ColoredChipSelectField
              control={control}
              fieldName="hasEuropeanPassport"
              label="Détention d'un passeport européen"
              options={Object.values(AnimalHealthDtoHasEuropeanPassport).map(
                (value: AnimalHealthDtoHasEuropeanPassport) => ({
                  label: getReadableBooleanOrUnknown(value),
                  color: getColorForBooleanOrUnknown(value),
                  value: value,
                })
              )}
              error={undefined}
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ColoredChipSelectField
              control={control}
              error={errors.vaccinationStatus}
              fieldName="vaccinationStatus"
              label="Statut primovaccination"
              options={Object.values(CreateAnimalBodyDtoVaccinationStatus).map(
                (status: CreateAnimalBodyDtoVaccinationStatus) => ({
                  label: getReadableAnimalVaccineStatus(status),
                  value: status,
                  color: getColorForAnimalVaccineStatus(status),
                })
              )}
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={12}>
            {species === 'ferret' ? (
              <ControlledSelectField
                control={control}
                error={errors.sterilizedForFerrets}
                fieldName="sterilizedForFerrets"
                label={'Stérilisation'}
                options={Object.values(AnimalHealthDtoSterilizedForFerrets).map(
                  (value: AnimalHealthDtoSterilizedForFerrets) => ({
                    label: getReadableSterilizedForFerrets(value),
                    value: value,
                  })
                )}
                requiredRule={undefined}
              />
            ) : (
              <ControlledSelectField
                control={control}
                error={errors.sterilizedOrNeutered}
                fieldName="sterilizedOrNeutered"
                label={'Stérilisation'}
                options={Object.values(AnimalHealthDtoSterilizedOrNeutered).map(
                  (value: AnimalHealthDtoSterilizedOrNeutered) => ({
                    label: getReadableBooleanOrUnknown(value),
                    value: value,
                  })
                )}
                requiredRule={undefined}
              />
            )}
          </Grid>

          {/* In and Out Fields */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{
                color: '#1e88e5',
                fontWeight: 'bold',
                mt: 3,
              }}
            >
              🚪 Informations de Prise en Charge
            </Typography>
            <Divider sx={{ mb: 3, borderColor: '#e0e0e0' }} />
          </Grid>

          <Grid item xs={12}>
            <ControlledDateField
              control={control}
              validate={(value) => isValidPastDateCoherence(value) || !value}
              error={errors.takeOverAt}
              fieldName={'takeOverAt'}
              label="Date de Prise en Charge par l'association"
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city-of-origin-input"
              label="Ville de prise en charge"
              type="text"
              fullWidth
              {...register('cityOfOrigin')}
              error={!!errors.cityOfOrigin}
              helperText={errors.cityOfOrigin?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="address-of-origin-input"
              label="Adresse de prise en charge"
              type="text"
              fullWidth
              {...register('addressOfOrigin')}
              error={!!errors.addressOfOrigin}
              helperText={errors.addressOfOrigin?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledSelectField
              control={control}
              error={errors.origin}
              fieldName="origin"
              label="Origine"
              options={Object.values(AnimalTakeOverDtoOrigin).map((origin) => ({
                label: getReadableTakeOverOrigin(origin),
                value: origin,
              }))}
              requiredRule={undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledSelectField
              control={control}
              error={errors.reason}
              fieldName="reason"
              label="Motif"
              options={Object.values(AnimalTakeOverDtoReason).map((reason) => ({
                label: getReadableTakeOverReason(reason),
                value: reason,
              }))}
              requiredRule={undefined}
            />
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            loading={loading}
            sx={{
              width: '200px',
              fontWeight: 'bold',
              backgroundColor: '#1e88e5',
              '&:hover': {
                backgroundColor: '#1565c0',
              },
            }}
          >
            Enregistrer
          </LoadingButton>
        </Box>
      </form>
    </Container>
  )
}

const characteristicsOptions = [
  {
    title: 'Habitat et mode de vie',
    options: [
      'Convient à la vie en appartement',
      'Ne convient pas à la vie en appartement',
      'Convient à la vie en maison',
      'Ne convient pas à la vie en maison',
      'Hypoallergénique',
    ],
  },
  {
    title: 'Vie sociale',
    options: [
      'Entente avec les enfants',
      'Ne s’entend pas avec les enfants',
      'Entente avec les chiens',
      'Ne s’entend pas avec les chiens',
      'Entente avec les chats',
      'Ne s’entend pas avec les chats',
      'Entente avec les autres animaux',
      'Ne s’entend pas avec les autres animaux',
    ],
  },
  {
    title: 'Traits de caractère',
    options: ['Joueur', 'Calin', 'Craintif', 'Peureux', 'Agressif', 'Timide', 'Bruyant', 'Propre'],
  },
]
