import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material'
import React from 'react'
import { getBestCustomerTypeLabel } from '../../../domain/Customer/CustomerDisplay'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { MemberCardSaveButton } from '../../Members/MemberCardSaveButton'

const sx = { marginTop: 2 }

interface AssociationDescriptionProps {
  association: CustomerDto
  onSubmit: (data: FormParams) => Promise<void>
}

interface FormParams {
  description: string | ''
}

export const AssociationSiteDescription: React.FC<AssociationDescriptionProps> = ({ association, onSubmit }) => {
  const getDefaultValues = (customer: CustomerDto): FormParams => ({
    description: customer?.description || '',
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(association, getDefaultValues)

  return (
    <Card sx={sx}>
      <CardHeader title={`Présentation ${getBestCustomerTypeLabel(association?.types)}`} />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            id="outlined-textarea"
            label="Décrivez votre organisation en quelques mots"
            placeholder="Description de votre organisation en quelques mots"
            multiline
            fullWidth
            {...register('description', { required: 'La description est requise' })}
            error={!!errors.description}
            helperText={errors.description?.message}
            InputLabelProps={{ shrink: true }}
          />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', ...sx }}>
            <MemberCardSaveButton isOwnAccount={false} />
          </Box>
        </form>
      </CardContent>
    </Card>
  )
}
