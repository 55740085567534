import { Search } from '@mui/icons-material'
import { Box, TextField, InputAdornment, Pagination, Typography } from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { BooleanOrAll } from '../../../domain/enums/Boolean.enum'
import { useAnimalStore } from '../../../store/AnimalStore'
import { useFetchOrRefreshAnimalsOnMount } from '../../../store/useFetchOrRefreshAnimalsOnMount'
import { AnimalsTable, SortColumn, SortDirection } from '../../Animals/AnimalsTable'
import { CircularProgressPanel } from '../../common/CircularProgressPanel'
import { isAnimalOfferPublished } from '../../../domain/Animal/isAnimalOfferPublished'
import { blue } from '../../theme'

export const AssociationAdoptableAnimals = () => {
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('adoptionStatus')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')
  const [page, setPage] = React.useState(1)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState('')
  const maxAnimalPerPage = 50

  const animalStore = useAnimalStore()

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query !== debouncedSearchQuery) {
        setDebouncedSearchQuery(query)
        setPage(1) // Reset page as well when searchQuery changes
      }
    }, 500),
    [debouncedSearchQuery]
  )

  useEffect(() => {
    debouncedSearch(searchQuery)

    // Clean up function
    return () => {
      debouncedSearch.cancel()
    }
  }, [searchQuery, debouncedSearch])

  useFetchOrRefreshAnimalsOnMount(
    page,
    maxAnimalPerPage,
    {
      myAnimalsOnly: false,
      hasIdentificationNumberFilter: BooleanOrAll.All,
      sterilizedOrNeuteredFilter: [],
      adoptionStatusFilter: ['adoptable'],
      stepFilter: [],
      cannotBeAdoptedStatusFilter: [],
      hasExitDateFilter: null,
      speciesFilter: [],
      sexFilter: null,
    },
    debouncedSearchQuery,
    sortColumn,
    sortDirection
  )
  const publishedAnimalsCount = animalStore.animals.filter((animal) => isAnimalOfferPublished(animal)).length
  return (
    <>
      <Typography variant="h5" sx={{ my: 4, fontSize: '1.2rem', fontWeight: 'bold', color: blue }}>
        Vos animaux à l’adoption - {`${publishedAnimalsCount} sur ${animalStore.animalsCount} publiés`}
      </Typography>
      <Box display="flex" flexDirection="row" flexWrap="nowrap">
        <TextField
          id="name-input"
          placeholder="Rechercher un animal (nom, nº d'immatriculation, membres...)"
          type="text"
          fullWidth
          value={searchQuery}
          onChange={onChangeSearch}
          sx={{
            borderRadius: 0,
            [`& fieldset`]: {
              borderRadius: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {animalStore.isLoading ? (
        <CircularProgressPanel />
      ) : (
        <AnimalsTable
          animals={animalStore.animals}
          setSortColumn={setSortColumn}
          setSortDirection={setSortDirection}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          disableFrontSorting={true}
        />
      )}
      <Pagination
        count={Math.ceil(animalStore.animalsCount / maxAnimalPerPage)}
        page={page}
        onChange={(_, page) => {
          setPage(page)
        }}
        sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, paddingBottom: 10 }}
      />
    </>
  )
}
