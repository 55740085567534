import {
  Box,
  Typography,
  Avatar,
  Stack,
  IconButton,
  Menu,
  Chip,
  Tooltip,
  MenuItem,
  colors,
  TextField,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useEffect, useRef, useState } from 'react'
import { ClearRounded, DoneRounded, InfoOutlined, MoreHoriz, PhotoCamera, Verified } from '@mui/icons-material'
import { getAccountProfilePhotoSrc } from '../../utils/S3-links'
import {
  fullName,
  getColorForAvailabilityStatus,
  getReadableAvailabilityStatus,
} from '../../domain/Account/AccountDisplay'
import { AccountDto, CreateAdopterBodyDto } from '../../interactors/gen/backendClient'
import { useMutation } from 'react-query'
// import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { DeleteMemberPrompt } from './DeleteMemberPrompt'
import { useAccountsStore } from '../../store/AccountsStore'
import { ModifyPasswordMemberPrompt } from './ModifyPasswordMemberPrompt'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { isUserAvailableToday } from '../../utils/isUserAvailableToday'
import { isColorDark } from '../../utils/isColorDark'
import { theme } from '../theme'
import { AddAdopterDialog } from '../Adopters/AddAdopterDialog'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../PATHS'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { AcacedReferralBanner } from './AcacedReferralBanner'

interface MemberAvatarAndNameProps {
  account: AccountDto
  isOwnAccount: boolean
  onSubmit: (account: Partial<AccountDto>) => void
}

const spacingItem = { marginTop: 2 }

export const MemberAvatarAndName: React.FC<MemberAvatarAndNameProps> = ({ account, isOwnAccount, onSubmit }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [previewURL, setPreviewURL] = useState<string | null>(null)
  const [isUploadDone, setIsUploadDone] = useState<boolean>(false)
  const [data, setData] = useState<CreateAdopterBodyDto | null>(null)
  const [openSteps, setOpenSteps] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [tempName, setTempName] = useState(account.firstName + ' ' + account.lastName)

  const nameInputRef = useRef<HTMLInputElement>(null) // Create a ref

  useEffect(() => {
    if (editMode && nameInputRef.current) {
      nameInputRef.current.focus() // Focus the input
      nameInputRef.current.select() // Select the text
    }
  }, [editMode])

  // Handle the save action
  const handleSave = () => {
    account.firstName = tempName.split(' ')[0]
    account.lastName = tempName.split(' ')[1]

    onSubmit(account)
    setEditMode(false)
  }

  // Handle cancel action
  const handleCancel = () => {
    setTempName(account.firstName + ' ' + account.lastName)
    setEditMode(false)
  }

  const handleImageSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null
    if (selectedFile) {
      setSelectedImage(selectedFile)
      setPreviewURL(URL.createObjectURL(selectedFile))
    }
    setIsUploadDone(false)
  }

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const toggleAccountStatus = async () => {
    onSubmit({ id: account.id, validated: !account.validated })
  }

  const accountsStore = useAccountsStore()
  const adoptersStore = useAdoptersStore()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()

  const adopterProfil = adoptersStore.adopters
    .filter((adopter) => adopter.isDeleted !== true)
    .find((adopter) => adopter.email === account.email)

  //Mutation pour l'upload du nouvel avatar
  const uploadMutation = useMutation(
    async () => await AccountsClient.uploadProfileImage(account?.id, selectedImage as File),
    {
      onSuccess: () => {
        globalSnackBarStore.triggerSuccessMessage(`La photo de profil de ${fullName(account)} a été mise à jour.`)
        setIsUploadDone(true)
      },
      onError: () => {
        globalSnackBarStore.triggerErrorMessage(
          `La photo de profil de ${fullName(account)} a echoué. Il est probable que l'image soit trop grosse.`
        )
        setIsUploadDone(true)
      },
    }
  )

  // TODO: remove any
  const profileImageKey = account?.images?.profileImageKey

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            alt={account ? fullName(account) : undefined}
            src={previewURL || (profileImageKey && getAccountProfilePhotoSrc(profileImageKey))}
            sx={{ width: 168, height: 168 }}
          />
          {/* Bouton permettant de changer la photo de profil */}
          {(accountsStore.canEdit() || isOwnAccount) && (
            <IconButton
              component="label"
              sx={{
                position: 'relative',
                bottom: 45,
                marginLeft: 14,
                backgroundColor: '#e0e0e0',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: '#bdbdbd',
                },
              }}
            >
              <PhotoCamera />
              <input hidden accept="image/*" multiple type="file" onChange={handleImageSelection} />
            </IconButton>
          )}
        </Box>
        <Stack direction="column" justifyContent="center" sx={{ marginLeft: 2 }}>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            {editMode ? (
              <Box display="flex" alignItems="center" sx={{ gap: 1, ml: 2 }}>
                <TextField
                  value={tempName}
                  onChange={(e) => setTempName(e.target.value)}
                  variant="standard"
                  inputRef={nameInputRef}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  autoFocus
                  sx={{ mr: 1, input: { fontSize: '2.125rem', color: colors.blue, width: '100%' } }}
                />

                <IconButton onClick={handleCancel} color="error" size="small">
                  <ClearRounded />
                </IconButton>
                <IconButton onClick={handleSave} color="primary" size="small">
                  <DoneRounded />
                </IconButton>
              </Box>
            ) : (
              <Typography variant="h4">{account ? fullName(account) : undefined}</Typography>
            )}
            {account.memberNumber && !editMode && (
              <Typography fontStyle="italic">(N°{account.memberNumber})</Typography>
            )}
            {account.validated && !editMode && <Verified sx={{ color: theme.palette.info.main }} />}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography fontStyle="italic">
              {account.restrainedData ? 'Profil restreint' : "A rejoint l'espace Petso"}
            </Typography>
            {account.restrainedData && (
              <Tooltip title="Certaines informations ne sont pas visibles compte tenu des préférences de l'utilisateur.">
                <InfoOutlined sx={{ color: theme.palette.primary.main }} />
              </Tooltip>
            )}
          </Box>
          <Box sx={{ maxWidth: '200px' }}>
            <Chip
              label={getReadableAvailabilityStatus(isUserAvailableToday(account))}
              sx={{
                mt: 1,
                backgroundColor: getColorForAvailabilityStatus(isUserAvailableToday(account)),
                color: isColorDark(getColorForAvailabilityStatus(isUserAvailableToday(account))) ? 'white' : 'black',
              }}
            />
          </Box>
        </Stack>
        <Box display="flex" alignItems="center" justifyContent="space-around" sx={spacingItem}>
          {previewURL && !isUploadDone && (
            <LoadingButton
              component="label"
              variant="contained"
              onClick={async () => {
                await uploadMutation.mutate()
              }}
              sx={{ marginLeft: 2 }}
              loading={uploadMutation.isLoading}
            >
              Mettre à jour la photo
            </LoadingButton>
          )}
        </Box>
        {
          /* Menu pour gérer le changement de mot de passe et la suppression du compte. */
          (accountsStore.isAdmin() || isOwnAccount) && (
            <Box display="flex" alignItems="center" justifyContent="space-around" sx={{ marginTop: 1, marginLeft: 1 }}>
              <IconButton onClick={handleOpenUserMenu}>
                <MoreHoriz />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  key="edit name"
                  onClick={() => {
                    setTempName(account.firstName + ' ' + account.lastName)
                    setEditMode(true)
                    handleCloseUserMenu()
                  }}
                >
                  {'Modifier le nom'}
                </MenuItem>
                {accountsStore.isAdmin() && (
                  <MenuItem onClick={toggleAccountStatus}>
                    {account.validated ? 'Invalider ce membre' : 'Valider ce membre'}
                  </MenuItem>
                )}
                {adopterProfil ? (
                  <MenuItem onClick={() => navigate(`/${PATHS.suiviAdoptant.relative}/${adopterProfil.id}`)}>
                    Voir le profil adoptant
                  </MenuItem>
                ) : (
                  <MenuItem
                    key="createAdopter"
                    onClick={() => {
                      setData({
                        firstName: account.firstName,
                        lastName: account.lastName || '',
                        email: account.email || '',
                        phoneNumber: account.phoneNumber,
                        address: account.address,
                        postalCode: account.postalCode,
                        city: account.city,
                        country: account.country,
                        sendForm: false,
                      })
                      setOpenSteps(true)
                    }}
                  >
                    Créer un profil adoptant à partir de ce membre
                  </MenuItem>
                )}

                {isOwnAccount && <ModifyPasswordMemberPrompt sx={spacingItem} />}
                <DeleteMemberPrompt account={account!} />
              </Menu>
            </Box>
          )
        }
      </Box>
      {/* <Button variant="contained">Inviter à rejoindre</Button> */}
      <AcacedReferralBanner />
      <AddAdopterDialog adopterData={data} open={openSteps} setOpen={setOpenSteps} secondaryPage />
    </Box>
  )
}
