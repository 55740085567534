import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useMutation } from 'react-query'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { AccountDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useNavigate } from 'react-router-dom'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  isOpen: boolean
  close: () => void
}

export const NewFeaturePopUp: React.FC<Props> = ({ isOpen, close }) => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(1) // Step 1: Introduction, Step 2: Webinar

  const account = accountsStore.connectedAccount

  const editAccountMutation = useMutation(
    async (account: AccountDto) => {
      return await AccountsClient.editAccount(account)
    },
    {
      onSuccess: (account) => {
        accountsStore.changeConnectedAccount(account)
        globalSnackBarStore.triggerSuccessMessage('Merci pour votre réponse')
        close()
      },
    }
  )

  const savePopupSeen = () => {
    if (!account) return
    editAccountMutation.mutate({
      ...account,
      hasSeenNewFeatures: true,
    })
  }

  const onCreateTemplate = () => {
    savePopupSeen()
    metricalpEvent({
      type: 'decided_to_create_template',
      customerId: account?.customerId,
      accountId: account?.id,
      accountName: account?.firstName + ' ' + account?.lastName,
    })
    navigate('/association?tab=templates&new=true')
  }

  const handleNextStep = () => {
    if (currentStep === 1) {
      setCurrentStep(2) // Move to webinar step
    } else {
      close() // Close dialog on final step
    }
  }

  const handleWebinarChoice = (choice: boolean) => {
    metricalpEvent({
      type: 'webinar_choice',
      customerId: account?.customerId,
      accountId: account?.id,
      choice: choice ? 'présent' : 'absent',
      accountName: account?.firstName + ' ' + account?.lastName,
      accountEmail: account?.email,
    })
    savePopupSeen()
    close()
  }

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 20 }}>
          {currentStep === 1 ? 'Gagnez 5 fois plus de temps !' : 'Inscription au webinaire'}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {currentStep === 1 && (
          <Box>
            <Typography variant="body1" gutterBottom sx={{ fontSize: 18 }}>
              Créez facilement vos modèles de documents (certificat & contrat d&apos;adoption) :
            </Typography>
            <ol>
              <li>Importez votre certificat ou contrat au format PDF.</li>
              <li>Insérez des variables dynamiques et ajoutez des champs à remplir.</li>
              <li>Générez vos documents en un clic pour chaque adoption.</li>
              <li>Faites-les signer électroniquement et suivez leur réception dans le suivi adoptant.</li>
            </ol>
            <Typography variant="body1" gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
              Notre tuto vidéo en 1 minute chrono !
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/1oO1jA-VLNo?autoplay=1&mute=1&start=30"
                title="YouTube video tutorial"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
              />
            </Box>
          </Box>
        )}
        {currentStep === 2 && (
          <Box>
            <Typography variant="body1" gutterBottom>
              Vous ne souhaitez pas commencer maintenant ? Participez à notre webinaire pour découvrir en détail cette
              nouvelle fonctionnalité.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b>Date :</b> Jeudi 28 novembre
              <br />
              <b>Heure :</b> 18h00
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {currentStep === 1 ? (
          <>
            <Button variant="outlined" color="secondary" onClick={handleNextStep} sx={{ textTransform: 'none' }}>
              Plus tard
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => {
                onCreateTemplate()
                close()
              }}
              sx={{ textTransform: 'none' }}
            >
              Créer mon premier modèle
            </LoadingButton>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleWebinarChoice(false)}
              sx={{ textTransform: 'none' }}
            >
              Non, merci
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => handleWebinarChoice(true)}
              sx={{ textTransform: 'none' }}
            >
              Oui, je participerai
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
