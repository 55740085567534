import React from 'react'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { Card, CardHeader, CardContent, Button } from '@mui/material'
import ReactQuill from 'react-quill'
import { getReadableAnimalFieldLabel } from '../../../../domain/Animal/AnimalDisplay'
import { TrimManager } from '../../../../utils/TrimManager'
import { EditorPrompt } from '../EditorPrompt'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'

interface Props {
  animal: AnimalDto
}

export const AnimalNoteCard: React.FC<Props> = ({ animal }) => {
  const editAnimalMutation = useEditAnimalMutation({})
  const canEditAnimal = useCanEditAnimal()

  const onEditLifeBeforeAssociation = async (newLifeBeforeAssociation: string) => {
    await editAnimalMutation.mutate({ ...animal, lifeBeforeAssociation: newLifeBeforeAssociation })
  }
  return (
    <Card>
      <CardHeader
        title={getReadableAnimalFieldLabel('lifeBeforeAssociation')}
        action={
          canEditAnimal && (
            <EditorPrompt
              initialValue={animal.lifeBeforeAssociation || ''}
              onSubmit={onEditLifeBeforeAssociation}
              title={getReadableAnimalFieldLabel('lifeBeforeAssociation')}
            />
          )
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <ReactQuill value={TrimManager.trimIfNeeded(animal.lifeBeforeAssociation)} readOnly={true} theme={'bubble'} />
        {TrimManager.needTrim(animal.lifeBeforeAssociation) && (
          <EditorPrompt
            title={getReadableAnimalFieldLabel('lifeBeforeAssociation')}
            initialValue={animal.lifeBeforeAssociation || ''}
            onSubmit={onEditLifeBeforeAssociation}
            renderButton={(onClick: () => void): JSX.Element => <Button onClick={onClick}>Voir Plus</Button>}
          />
        )}
      </CardContent>
    </Card>
  )
}
