import { LoadingButton } from '@mui/lab'
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import { isAdmin } from '../../../domain/Account/isAdmin'
import { backendClient } from '../../../interactors/clients/client'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { colors, spacingItem, theme } from '../../theme'
import { ChoosePaymentPlanModal } from './ChoosePaymentPlanModal'

interface RenewSubscriptionData {
  stripeCustomerId: string
}

interface PortalSessionData {
  customerId: string
}

interface Props {
  customer: CustomerDto | null
}

export const EssentialOffer: React.FC<Props> = ({ customer }) => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const currentUserIsAdmin = accountsStore.connectedAccount ? isAdmin(accountsStore.connectedAccount) : false
  const renewMutation = useMutation(
    (data: RenewSubscriptionData) => backendClient.post('/stripe/reactivate-subscription', data),
    {
      onSuccess: () => {
        globalSnackbarStore.triggerSuccessMessage('Votre abonnement a bien été renouvelé')
      },
      onError: (error) => {
        // Handle error.
        console.error('Error Creating Checkout Session:', error)
      },
    }
  )

  const dashboardMutation = useMutation(
    (data: PortalSessionData) => backendClient.post('/stripe/create-portal-session-customer-id', data),
    {
      onSuccess: (data) => {
        window.location.href = data.data
      },
      onError: (error) => {
        // Handle error.
        console.error('Error Creating Portal Session:', error)
      },
    }
  )

  const handleClick = async () => {
    if (hasCanceledSubscription && customer?.stripeCustomerId) {
      try {
        const { data: session } = await renewMutation.mutateAsync({ stripeCustomerId: customer.stripeCustomerId })
        console.log('Subscription renewed:', session)
      } catch (error) {
        // Handle error
        console.error('Error Renewing Subscription:', error)
      }
    } else if (isSubscribed) {
      const customerId = customer?.stripeCustomerId || ''

      try {
        const { data: session } = await dashboardMutation.mutateAsync({ customerId })
        console.log('Portal Session Created:', session)
      } catch (error) {
        // Handle error
        console.error('Error Creating Portal Session:', error)
      }
    }
  }

  const hasEssentialPlus = customer?.subscription?.plan === 'essential'

  const isSubscribed = customer?.subscription?.status === 'active'

  const hasCanceledSubscription = customer?.subscription?.status === 'canceled'

  return (
    <Card sx={{ px: 2, py: 2 }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 26, fontWeight: 700, color: theme.palette.primary.main }}
        >{`L'essentiel +`}</Typography>
        <Typography
          sx={{ fontSize: 18, fontWeight: 700, color: colors.cello }}
        >{`Faciliter le suivi de santé et l’adoption`}</Typography>
        <Typography sx={{ fontSize: 26, fontWeight: 700, color: colors.cello, mt: 1 }}>
          {`18 Euros/Mois TTC`} <span style={{ fontSize: 14 }}>150€/an (~15%)</span>
        </Typography>
        <Typography
          variant="h5"
          color={colors.bermudaGray}
          fontSize={14}
          sx={{ textAlign: 'left', height: { xs: '100%', md: '40px' } }}
        >
          {hasEssentialPlus
            ? hasCanceledSubscription
              ? `Vous avez annulé votre abonnement, il prendra fin le ${
                  customer.subscription?.expiresAt
                    ? new Date(customer.subscription?.expiresAt).toLocaleDateString('fr-FR')
                    : ''
                }`
              : 'Vous possédez cette offre'
            : 'Configuration d’un espace -  10 licences d’accès'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: 2,
            gap: 1,
          }}
        >
          {hasEssentialPlus ? (
            <Tooltip title={!currentUserIsAdmin ? "Seuls les administrateurs peuvent gérer l'abonnement" : ''}>
              <span>
                <LoadingButton
                  onClick={() => handleClick()}
                  variant="contained"
                  loading={renewMutation.isLoading || dashboardMutation.isLoading}
                  sx={{
                    ...spacingItem,
                    fontWeight: 400,
                    fontSize: 24,
                    color: 'white',
                    width: '350px',
                    fontFamily: 'Insaniburger',
                  }}
                  disabled={!currentUserIsAdmin}
                >
                  {hasCanceledSubscription ? 'Renouveler mon offre' : 'Gérer mon abonnement'}
                </LoadingButton>
              </span>
            </Tooltip>
          ) : (
            <ChoosePaymentPlanModal
              offerName="essentiel +"
              prices={['15', '150']}
              priceIds={['price_1O4JLQKwIFnHkxNPsRR2QmjR', 'price_1O4JMFKwIFnHkxNPuoJnMf8S']}
              disabled={!currentUserIsAdmin}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 4, ml: 3 }}>
          <Typography sx={{ fontSize: 14.5 }}>Suivi pré-adoption et historique de santé</Typography>
          <Typography sx={{ fontSize: 14.5 }}>
            Rappels sanitaire et admins : vermifuges, vaccination, surveillance vétérinaire…
          </Typography>
          <Typography sx={{ fontSize: 14.5 }}>Stockage et centralisations des documents</Typography>
          <Typography sx={{ fontSize: 14.5 }}>Gestion des statuts d’adoption</Typography>
          <Typography sx={{ fontSize: 14.5 }}>Publication des annonces en un clic sur Petso</Typography>
          <Typography sx={{ fontSize: 14.5 }}>Création de vos certificats et contrats</Typography>
          <Typography sx={{ fontSize: 14.5 }}>Automatisation de l’envoi des documents</Typography>
          <Typography sx={{ fontSize: 14.5 }}>Intégration de signatures électroniques</Typography>
          <Typography sx={{ fontSize: 14.5 }}>Exportation de vos registres et bons vétérinaires</Typography>
          <Typography sx={{ fontSize: 14.5 }}>Ajout de vos membres selon 4 permissions</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
