import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  TableCell,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from '@mui/material'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../domain/Account/AccountDisplay'
import {
  getColorForAnimalAdoptionStatus,
  getEmojiSpecies,
  getReadableAnimalAdoptionStatus,
  getReadableCannotBeAdoptedStatus,
  getReadableIdentificationNumber,
} from '../../domain/Animal/AnimalDisplay'
import { isAnimalOfferPublished } from '../../domain/Animal/isAnimalOfferPublished'
import {
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoStatus,
  AnimalDtoSex,
  AnimalInsightsDtoType,
  AnimalSearchItemDto,
} from '../../interactors/gen/backendClient'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { isColorDark } from '../../utils/isColorDark'
import { setOpacity } from '../../utils/setOpacity'
import { InfoBox, MessageType } from '../common/InfoBox'
import { colors, lightGrey } from '../theme'

type ItemProps = {
  animal: AnimalSearchItemDto
  myAnimalsSection?: boolean
  assignAnimalId?: React.Dispatch<React.SetStateAction<string | null>>
  selectedAnimalId?: string
  selectedAnimals?: Array<AnimalSearchItemDto>
  setSelectedAnimals?: React.Dispatch<React.SetStateAction<Array<AnimalSearchItemDto>>>
}

export const AnimalsTableItem: React.FC<ItemProps> = ({
  animal,
  myAnimalsSection = false,
  assignAnimalId,
  selectedAnimalId,
  selectedAnimals = [],
  setSelectedAnimals,
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (assignAnimalId) {
      assignAnimalId(animal.id)
      return
    }
    return navigate(`/animaux/${animal.id}`)
  }

  // const memberInChargeAccountId = animal.responsibilities?.memberInChargeAccountId
  // const hostFamilyInChargeAccountId = animal.responsibilities?.hostFamilyInChargeAccountId

  // const members = accountsStore.members
  // const memberInCharge = members.find((member) => member.id === memberInChargeAccountId)
  // const hostFamilyInCharge = members.find((member) => member.id === hostFamilyInChargeAccountId)
  const profileImageKey = animal.images?.profileImageKey
  const { responsibilities } = animal
  const order: Array<AnimalInsightsDtoType> = ['info', 'error', 'warning']

  const isSelected = selectedAnimals.some((selected) => selected.id === animal.id)

  const handleSelectAnimal = (event: React.MouseEvent) => {
    event.stopPropagation() // Prevent triggering the row navigation
    if (!setSelectedAnimals) return
    setSelectedAnimals((prev) => {
      if (isSelected) {
        return prev.filter((selected) => selected.id !== animal.id) // Remove animal if already selected
      } else {
        return [...prev, animal] // Add animal
      }
    })
  }

  return (
    <TableRow
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        backgroundColor:
          selectedAnimalId === animal.id
            ? setOpacity(colors.bermudaGray, 0.35)
            : backgroundShouldBeGrey(animal, myAnimalsSection)
            ? lightGrey
            : '',
      }}
    >
      <TableCell>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 70,
            height: 70,
            backgroundColor: isSelected ? colors.bermudaGray : 'transparent',
            borderRadius: '50%',
            overflow: 'hidden',
            transition: 'background-color 0.3s ease',
            boxShadow: isSelected ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: setOpacity(colors.bermudaGray, 0.15),
            },
          }}
          onClick={handleSelectAnimal}
        >
          <Avatar
            alt={animal.name}
            src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
            sx={{
              width: 60,
              height: 60,
              border: isSelected ? `3px solid ${colors.bermudaGray}` : '2px solid #ffffff',
              transition: 'border 0.2s ease-in-out',
            }}
          />
          {isSelected && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#ffffff',
                  fontSize: 24,
                  fontWeight: 'bold',
                  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
                }}
              >
                ✔
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>

      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        {animal.newName && animal.newName !== '' ? animal.newName : animal.name}
        {animal.newName && <span style={{ fontWeight: 400 }}>{`- ex ${animal.name}`}</span>}
      </TableCell>

      <TableCell component="th" scope="row">
        {animal.takeOverAt ? dayjs(animal.takeOverAt).format('DD/MM/YY') : ''}
      </TableCell>

      <TableCell component="th" scope="row">
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              fontSize: 22,
            }}
          >
            {getEmojiSpecies(animal.species)}
            {/* <AnimalSpeciesIcon species={animal.species} /> */}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              paddingTop: '5px',
            }}
          >
            {animal.sex && <AnimalSexIcon sex={animal.sex} />}
            {/* {getEmojiAnimalSex(animal.sex)} */}
          </Typography>
        </Box>
      </TableCell>

      <TableCell component="th" scope="row">
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <HtmlTooltip
            onClick={(e) => e.stopPropagation()}
            title={
              <React.Fragment>
                {animal.insights
                  .sort((a, b) => {
                    const typeA = a.type
                    const typeB = b.type

                    // Get the index of each type in the order array
                    const indexA = order.indexOf(typeA)
                    const indexB = order.indexOf(typeB)

                    // Compare the indexes to determine the sorting order
                    return indexA - indexB
                  })
                  .map((insight, index) => {
                    // eslint-disable-next-line react/jsx-key
                    return (
                      <InfoBox
                        key={index}
                        messageType={insight.type}
                        content={insight.message}
                        sx={{ marginBottom: 1 }}
                      />
                    )
                  })}
              </React.Fragment>
            }
          >
            <AvatarGroup>
              {animal.insightsCounters.info !== 0 && (
                <CircleWithNumber messageType="info" number={animal.insightsCounters.info} />
              )}
              {animal.insightsCounters.warning !== 0 && (
                <CircleWithNumber messageType="warning" number={animal.insightsCounters.warning} />
              )}
              {animal.insightsCounters.error !== 0 && (
                <CircleWithNumber messageType="error" number={animal.insightsCounters.error} />
                // <Avatar alt={`${animal.insightsCounters.error}`} sx={{ backgroundColor: 'main.theme.palette.error.main' }} />
              )}
              {animal.insightsCounters.success !== 0 && (
                <CircleWithNumber messageType="success" number={animal.insightsCounters.success} />
              )}

              {animal.insightsCounters.error === 0 &&
                animal.insightsCounters.warning === 0 &&
                animal.insightsCounters.info === 0 &&
                animal.insightsCounters.success === 0 && (
                  <CircleWithNumber messageType="disabled" />
                  // <Avatar alt={`${animal.insightsCounters.error}`} sx={{ backgroundColor: 'main.theme.palette.error.main' }} />
                )}
            </AvatarGroup>
          </HtmlTooltip>
        </Box>
      </TableCell>

      <TableCell component="th" scope="row">
        {responsibilities?.memberInChargeAccount && fullName(responsibilities?.memberInChargeAccount)}
      </TableCell>

      <TableCell component="th" scope="row">
        {responsibilities?.hostFamilyInChargeAccount
          ? fullName(responsibilities?.hostFamilyInChargeAccount)
          : animal.responsibilities?.cageNumber && `Box N°${animal.responsibilities?.cageNumber}`}
      </TableCell>

      <TableCell component="th" scope="row" sx={{ maxWidth: 260 }}>
        <AnimalStatusChip animal={animal} />
      </TableCell>

      {/* <TableCell component="th" scope="row">
          {animal.takeOver.cityOfOrigin || ''}
        </TableCell> */}

      <TableCell component="th" scope="row">
        {animal.birthdate ? dayjs(animal.birthdate).format('DD/MM/YY') : ''}
      </TableCell>

      <TableCell component="th" scope="row">
        {animal.identificationNumber ? getReadableIdentificationNumber(animal.identificationNumber?.toString()) : ''}
      </TableCell>
    </TableRow>
  )
}

export const backgroundShouldBeGrey = (animal: AnimalSearchItemDto, myAnimalsSection: boolean) => {
  if (
    animal.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
    (animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Dead ||
      animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Released)
  ) {
    return true
  }
  if (
    myAnimalsSection &&
    animal.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
    (animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.Lost ||
      animal.cannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation)
  ) {
    return true
  }
  if (animal.adoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted && myAnimalsSection) {
    return true
  }
  return false
}
export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: useMediaQuery('(max-width: 600px') ? '' : '300px',
    minWidth: useMediaQuery('(max-width: 600px') ? '300px' : '',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

interface CircleWithNumberProps {
  number?: number
  messageType: MessageType | 'disabled'
}

export const CircleWithNumber: FC<CircleWithNumberProps> = ({ number, messageType }) => {
  return (
    <Avatar
      sx={{
        background: (theme) =>
          messageType === 'disabled' ? theme.palette.grey['300'] : setOpacity(theme.palette[messageType].main, 0.6),
        fontSize: '14px',
        width: '25px',
        height: '25px',
        fontWeight: 'bold',
      }}
    >
      {number || ' '}
    </Avatar>
  )
}

interface AnimalSexIconProps {
  sex: AnimalDtoSex
}

export const AnimalSexIcon: FC<AnimalSexIconProps> = ({ sex }) => {
  const map: Record<AnimalDtoSex, JSX.Element> = {
    [AnimalDtoSex.Female]: <BsGenderFemale style={{ color: 'pink' }} />,
    [AnimalDtoSex.Male]: <BsGenderMale style={{ color: '#3f7cbf' }} />,
  }

  return map[sex]
}

interface StatusChipProps {
  animal: AnimalSearchItemDto
}

export const AnimalStatusChip: React.FC<StatusChipProps> = ({ animal }) => {
  return (
    <Chip
      label={
        animal.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted && animal.cannotBeAdoptedStatus
          ? getReadableAnimalAdoptionStatus(animal.adoptionStatus) +
            ' - ' +
            getReadableCannotBeAdoptedStatus(animal.cannotBeAdoptedStatus, { short: true })
          : animal.adoptionStatus === 'adoptable'
          ? `${getReadableAnimalAdoptionStatus(animal.adoptionStatus)} - ${
              isAnimalOfferPublished(animal) ? 'Publié' : 'Non publié'
            }`
          : getReadableAnimalAdoptionStatus(animal.adoptionStatus)
      }
      sx={{
        backgroundColor: setOpacity(getColorForAnimalAdoptionStatus(animal.adoptionStatus), 0.8),
        color: isColorDark(getColorForAnimalAdoptionStatus(animal.adoptionStatus)) ? '#fff' : '#000',
      }}
    />
  )
}
