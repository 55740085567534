import { Autocomplete, CircularProgress, Grid, TextField, Typography, Chip, Box } from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { AnimalAdoptionDtoStatus, AnimalDtoSpecies } from '../../interactors/gen/backendClient'
import { getEmojiSpecies, getReadableAnimalAdoptionStatus } from '../../domain/Animal/AnimalDisplay'

type Option = {
  label: string
  value: string
  species: AnimalDtoSpecies
  adoptionStatus: AnimalAdoptionDtoStatus
}

type Props = {
  label?: string
  selectedAnimals: Option[]
  setSelectedAnimals: React.Dispatch<React.SetStateAction<Option[]>>
}

export const AnimalsAutocomplete: React.FC<Props> = ({ label, selectedAnimals, setSelectedAnimals }) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [loading, setLoading] = useState(false)

  const fetchData = async (search: string) => {
    if (!search) {
      return
    }

    setLoading(true)
    try {
      const animals = await AnimalsClient.getAllAnimal(1, 50, undefined, search)
      const newOptions = animals.map((animal) => ({
        label: animal.name,
        value: animal.id,
        species: animal.species,
        adoptionStatus: animal.adoptionStatus,
      }))
      setOptions(newOptions)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const debouncedFetchData = useCallback(
    debounce((search: string) => fetchData(search), 1000),
    []
  )

  const handleInputChange = (_: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    setInputValue(newInputValue)
    debouncedFetchData(newInputValue)
  }

  const handleOptionChange = (_: React.SyntheticEvent, newSelectedOptions: Option[]) => {
    setSelectedAnimals(newSelectedOptions)
    setInputValue('') // Clear the input field after selection
  }

  const handleDeleteChip = (animalId: string) => {
    const updatedAnimals = selectedAnimals.filter((animal) => animal.value !== animalId)
    setSelectedAnimals(updatedAnimals)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Autocomplete
        multiple
        options={options}
        value={selectedAnimals}
        onChange={handleOptionChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        loading={loading}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        noOptionsText={
          inputValue.length > 0 ? 'Aucun animal correspondant à votre recherche.' : "Saisissez le nom de l'animal"
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label || 'Rechercher des animaux'}
            placeholder="Tapez pour rechercher"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              label={`${getEmojiSpecies(option.species as AnimalDtoSpecies)} ${option.label}`}
              {...getTagProps({ index })}
              key={option.value}
              onDelete={() => handleDeleteChip(option.value)}
              sx={{ margin: 0.5 }}
            />
          ))
        }
        renderOption={(props, option) => (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                {getEmojiSpecies(option.species as AnimalDtoSpecies)}
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                <Typography variant="body1">{option.label}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {getReadableAnimalAdoptionStatus(option.adoptionStatus as AnimalAdoptionDtoStatus)}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )}
      />
    </Box>
  )
}
