import { Check, Close, ContentCopy, ReportGmailerrorredOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, DialogContent, IconButton, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../theme'

interface Props {
  submitStep: (data: {
    animalId?: string
    files?: File[]
    deliverLater?: boolean
    isLocked?: boolean
    certificateTemplateKey?: string
    contractTemplateKey?: string
  }) => void
  hasAnimal: boolean
  close: () => void
  paymentLink?: string
  price?: number
  adopterFirstName: string
  adopterEmail: string
  animalName: string
  customerName: string
}

export const SummaryStep: React.FC<Props> = ({
  submitStep,
  hasAnimal,
  close,
  paymentLink = '',
  adopterFirstName,
  adopterEmail,
  animalName,
  customerName,
  price = 0,
}) => {
  /* const paymentReceived = 2 // Example value
  const maxPayments = 3 */

  const handleCopyEmailContent = () => {
    const emailContent = `
Bonjour,
Vous adoptez ${animalName} auprès de notre association, merci !
Comme évoqué, l'adoption implique le règlement de frais, fixés à ${price}€, afin de couvrir les soins apportés à ${animalName} ainsi que les frais de fonctionnement de notre association et nos partenaires.

Dans ce cadre, je vous invite à régler les frais d’adoption en ligne via notre partenaire Petso. Cet outil nous aide à suivre nos animaux et à gérer efficacement notre association. Voici le lien pour effectuer le paiement :
${paymentLink}

Si vous préférez un autre mode de paiement, n’hésitez pas à nous contacter. Merci pour votre compréhension et votre engagement en faveur de nos animaux !

${customerName}`
    navigator.clipboard.writeText(emailContent)
    alert('Contenu de l’email copié dans le presse-papiers.')
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(paymentLink)
    alert('Lien de paiement copié dans le presse-papiers.')
  }

  return (
    <DialogContent>
      <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={close}>
        <Close />
      </IconButton>

      {hasAnimal ? (
        <Box sx={{ textAlign: 'center', padding: '1.5rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mb: 3, textAlign: 'left' }}>
            <ReportGmailerrorredOutlined sx={{ color: colors.cello, fontSize: '3rem' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  color: colors.cello,
                }}
              >
                Procédure d’adoption créée
              </Typography>
              <Typography sx={{ color: '#757575' }}>
                <b>Petso est gratuit : </b> invitez l’adoptant à régler les frais d’adoption en ligne via ce lien.
              </Typography>
            </Box>
          </Box>
          {/* Gauge */}
          {/* <Box sx={{ position: 'relative', width: '42%', margin: '0 auto', mb: 1 }}>
            <LinearProgress
              variant="determinate"
              value={(paymentReceived / maxPayments) * 100}
              sx={{
                height: 30,
                borderRadius: 5,
                backgroundColor: '#e0e0e0',
                '& .MuiLinearProgress-bar': {
                  borderRadius: '5px 0 0 5px',
                  backgroundColor: colors.cello,
                },
              }}
            />
          </Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '1rem',
              color: colors.cello,
            }}
          >
            {paymentReceived}/{maxPayments} règlements reçus depuis le 01/01
          </Typography> */}
          <Typography sx={{ fontWeight: 'bold', color: '#333', my: 2 }}>
            Demander à {adopterFirstName} de contribuer : {adopterEmail}
          </Typography>
          {/* Message Box */}
          <Box
            sx={{
              backgroundColor: '#f9f9f9',
              padding: 2,
              borderRadius: 2,
              border: '1px solid #ddd',
              textAlign: 'left',
              position: 'relative',
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 5,
                right: 5,
              }}
              size="small"
              color="secondary"
              onClick={handleCopyEmailContent}
            >
              <ContentCopy fontSize="small" />
            </IconButton>
            <Typography variant="body2" sx={{ color: '#424242' }}>
              Bonjour,
              <br />
              Vous adoptez {animalName} auprès de notre association, merci ! Comme évoqué, l&apos;adoption implique le
              règlement de frais, fixés à {price}€, afin de couvrir les soins apportés à {animalName} ainsi que les
              frais de fonctionnement de notre association et nos partenaires.
              <br />
              <br />
              Dans ce cadre, je vous invite à régler les frais d’adoption en ligne via notre partenaire Petso. Cet outil
              nous aide à suivre nos animaux et à gérer efficacement notre association. Voici le lien pour effectuer le
              paiement :{' '}
              <a href={paymentLink} target="_blank" rel="noreferrer">
                {paymentLink}
              </a>
              <br />
              <br />
              Si vous préférez un autre mode de paiement, n’hésitez pas à nous contacter. Merci pour votre compréhension
              et votre engagement en faveur de nos animaux !
              <br />
              <br />
              {customerName}
            </Typography>
          </Box>
          <Typography sx={{ my: 1 }}>- ou -</Typography>

          {/* Copy Link Button */}
          <Box
            sx={{
              backgroundColor: '#f9f9f9',
              padding: 2,
              borderRadius: 2,
              border: '1px solid #ddd',
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ fontSize: '0.875rem', color: '#333' }}>Lien de paiement : {paymentLink}</Typography>
            <Button
              variant="text"
              color="secondary"
              onClick={handleCopyLink}
              sx={{ textTransform: 'none', padding: 0, minWidth: 0 }}
            >
              <ContentCopy sx={{ fontSize: '1rem', ml: 0.5 }} />
            </Button>
          </Box>
          {/* Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => submitStep({})}
              sx={{
                fontWeight: 'bold',
                mt: 1,
                backgroundColor: colors.cello,
              }}
            >
              Accéder à la procédure
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Avatar sx={{ height: '80px', width: '80px', backgroundColor: colors.lightGreen }}>
            <Check />
          </Avatar>
          <Typography variant="h6">L’adoptant a bien été créé</Typography>
          <Button variant="contained" color="primary" onClick={() => submitStep({})}>
            Aller voir
          </Button>
        </Box>
      )}
    </DialogContent>
  )
}
