import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, SxProps, Typography } from '@mui/material'
import React from 'react'
import ReactQuill from 'react-quill'
import { getReadableAnimalFieldLabel, getReadableAnimalHairLength } from '../../../../domain/Animal/AnimalDisplay'
import { AnimalDto } from '../../../../interactors/gen/backendClient'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { TrimManager } from '../../../../utils/TrimManager'
import { ControlledChipInput } from '../../../common/ControlledChipInput'
import { ControlledMultipleFreeSoloAutoCompleteWithCategories } from '../../../common/ControlledMultipleFreeSoloAutocompleteWithCategories'
import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { blueSky, spacingItem } from '../../../theme'
import { AnimalCardSaveButton } from '../AnimalCardSaveButton'
import { EditorPromptWithFields } from '../EditorPromptWithFields'
import { AnimalOtherImagesCard } from './AnimalOtherImagesCard'

interface Props {
  animal: AnimalDto
  sx?: SxProps
}

type FormParams = {
  characteristics: Array<string>
  identifyingMarks: Array<string>
}

export const AnimalAboutCard: React.FC<Props> = ({ animal }) => {
  const editAnimalMutation = useEditAnimalMutation({})
  const canEditAnimal = useCanEditAnimal()

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    characteristics: animal?.characteristics || [],
    identifyingMarks: animal?.identifyingMarks || [],
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useFormExtended(animal, getDefaultValues)

  const onSubmit = async (data: FormParams) => {
    await editAnimalMutation.mutate({
      ...animal,
      characteristics: data.characteristics,
      identifyingMarks: data.identifyingMarks,
    })
  }

  const options = [
    {
      title: 'Habitat et mode de vie',
      options: [
        'Convient à la vie en appartement',
        'Ne convient pas à la vie en appartement',
        'Convient à la vie en maison',
        'Ne convient pas à la vie en maison',
        'Hypoallergénique',
      ],
    },
    {
      title: 'Vie sociale',
      options: [
        'Entente avec les enfants',
        'Ne s’entend pas avec les enfants',
        'Entente avec les chiens',
        'Ne s’entend pas avec les chiens',
        'Entente avec les chats',
        'Ne s’entend pas avec les chats',
        'Entente avec les autres animaux',
        'Ne s’entend pas avec les autres animaux',
      ],
    },
    {
      title: 'Traits de caractère',
      options: ['Joueur', 'Calin', 'Craintif', 'Peureux', 'Agressif', 'Timide', 'Bruyant', 'Propre'],
    },
  ]
  return (
    <Card>
      <CardHeader
        title={`Présentation de ${animal.name}`}
        action={canEditAnimal && <EditorPromptWithFields title={`Présentation de ${animal.name}`} animal={animal} />}
        sx={{ pb: 0 }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent sx={{ pt: 0 }}>
          <Typography variant="h5" fontStyle="italic" color={blueSky} fontSize={13}>
            Si adoptable, cette présentation apparaitra dans l’annonce de {animal.name} sur Petso.fr
          </Typography>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                <ReadOnlyItem
                  label={getReadableAnimalFieldLabel('hair')}
                  value={animal.hairLength ? getReadableAnimalHairLength(animal.hairLength) : animal.hair || '..'}
                />

                <ReadOnlyItem label={getReadableAnimalFieldLabel('color')} value={animal.color || '..'} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography
                variant={'body2'}
                color="textSecondary"
                sx={{
                  lineHeight: '0.875rem',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  textDecoration: 'none',
                  mb: 1,
                }}
              >
                Description
              </Typography>
              <ReactQuill value={TrimManager.trimIfNeeded(animal.description)} readOnly={true} theme={'bubble'} />
              {TrimManager.needTrim(animal.description) && (
                <EditorPromptWithFields
                  title={getReadableAnimalFieldLabel('description')}
                  renderButton={(onClick: () => void): JSX.Element => <Button onClick={onClick}>Voir Plus</Button>}
                  animal={animal}
                />
              )}
            </Grid>
          </Grid>

          <ControlledMultipleFreeSoloAutoCompleteWithCategories
            options={options}
            freeSolo={true}
            control={control}
            error={undefined}
            fieldName="characteristics"
            label="Caractéristiques"
            requiredRule=""
            sx={spacingItem}
          />
          <ControlledChipInput
            control={control}
            error={undefined}
            fieldName="identifyingMarks"
            label="Signes distinctifs"
            requiredRule=""
            helperText={`Entrez un signe distinctif puis appuyez sur "entrée" pour l'ajouter à la liste`}
            sx={spacingItem}
          />
        </CardContent>
        <AnimalOtherImagesCard animal={animal} />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!isDirty} />
        </CardActions>
      </form>
    </Card>
  )
}
