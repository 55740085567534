import { InfoOutlined } from '@mui/icons-material'
import { Box, Chip, SxProps, Theme, Tooltip, Typography, Popover } from '@mui/material'
import React, { FC, useState } from 'react'
import { isColorDark } from '../../utils/isColorDark'

interface ReadOnlyChipsWithOverflowProps {
  className?: string
  label: string
  values: string[]
  tooltip?: string
  sx?: SxProps<Theme>
  size?: 'medium' | 'small'
  chipColor?: string
  forceChipTextWhite?: boolean
  maxVisible?: number // Maximum chips to show before overflow
}

export const ReadOnlyChipsWithOverflow: FC<ReadOnlyChipsWithOverflowProps> = ({
  className,
  label,
  values,
  tooltip,
  sx,
  size = 'medium',
  chipColor,
  maxVisible = 3,
  forceChipTextWhite = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const isPopoverOpen = Boolean(anchorEl)
  const visibleChips = values.slice(0, maxVisible)
  const hiddenChips = values.slice(maxVisible)

  return (
    <Box className={className} sx={sx}>
      <Box display="flex" alignItems="center" sx={{ marginBottom: 1 }}>
        <Typography
          variant={size === 'small' ? 'caption' : 'body2'}
          color="textSecondary"
          sx={{
            lineHeight: '0.875rem',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textDecoration: 'none',
          }}
        >
          {label}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="right">
            <InfoOutlined color="primary" sx={{ marginLeft: 3, width: 14, height: 14 }} />
          </Tooltip>
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1} flexWrap="nowrap" overflow="hidden">
        {visibleChips.map((chip, index) => (
          <Chip
            key={index}
            sx={{
              color: forceChipTextWhite ? '#fff' : isColorDark(chipColor || '#fff') ? '#fff' : '#000',
              backgroundColor: chipColor,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            label={chip}
            size={size}
          />
        ))}
        {hiddenChips.length > 0 && (
          <Chip
            label={`+${hiddenChips.length}`}
            onMouseEnter={handlePopoverOpen}
            sx={{
              backgroundColor: '#e0e0e0',
              cursor: 'pointer',
            }}
            size={size}
          />
        )}
      </Box>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: handlePopoverOpen, // Keep the popover open when hovering over it
          onMouseLeave: handlePopoverClose, // Close the popover when the mouse leaves
        }}
      >
        <Box
          sx={{
            padding: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            maxHeight: 200,
            overflowY: 'auto',
          }}
        >
          {hiddenChips.map((chip, index) => (
            <Chip
              key={index}
              label={chip}
              sx={{
                color: forceChipTextWhite ? '#fff' : isColorDark(chipColor || '#fff') ? '#fff' : '#000',
                backgroundColor: chipColor,
              }}
              size={size}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  )
}
