import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router-dom'
import { AuthService } from './interactors/services/AuthService'
import { useGlobalLoaderStore } from './store/GlobalLoaderStore'
import { AddAnimalScreen } from './views/Animals/AddAnimalScreen'
import { CircularProgressPanel } from './views/common/CircularProgressPanel'
import { LoginPage } from './views/Login/LoginPage'
import { AddMemberScreen } from './views/Members/AddMemberScreen'
import { PATHS } from './views/PATHS'
import { theme } from './views/theme'
import { PrivateRoutes } from './views/utils/PrivateRoutes'

import { MetricalpReactProvider } from '@metricalp/react'
import { Navigate } from 'react-router-dom'
import { ErrorsClient } from './interactors/clients/ErrorClient'
import { useAccountsStore } from './store/AccountsStore'
import { ScrollProvider } from './utils/hooks/ScrollContext'
import { AddAdopterScreen } from './views/Adopters/AddAdopterScreen'
import { AdopterDetailsScreen } from './views/Adopters/AdopterDetailsScreen'
import { AdoptersListScreen } from './views/Adopters/AdoptersListScreen'
import { AssignAnimalToAdopter } from './views/Adopters/AssignAnimalToAdopter'
import { AnimalListScreen } from './views/Animals/AnimalsListScreen'
import { AnimalDetailsScreen } from './views/Animals/Details/AnimalDetailsScreen'
import { AssociationDetailsScreen } from './views/Association/AssociationDetailsScreen'
import { AddDonatorScreen } from './views/Association/DonationTracking/AddDonatorScreen'
import { SignatureScreen } from './views/Association/EditSignatureScreen'
import { EditTemplateDocument } from './views/Association/EditTemplateDocument'
import { SelectZonesScreen } from './views/Association/SelectZonesScreen'
import { GlobalSnackBar } from './views/common/GlobalSnackbar'
import { AdminLoginPage } from './views/Login/AdminLoginPage'
import { MapScreen } from './views/Map/MapScreen'
import { MemberDetailsScreen } from './views/Members/MembersDetailsScreen'
import { ContactListScreen } from './views/Members/MembersListScreen'
import { VeterinariesListScreen } from './views/Members/VeterinariesListScreen'
import { ResetPasswordPage } from './views/ResetPassword/ResetPasswordPage'
import { CGUPopUp } from './views/utils/CGUPopUp'
import { NewFeaturePopUp } from './views/utils/NewFeaturePopUp'
import { AnimalBulkEdit } from './views/Animals/AnimalBulkEdit'

const queryClient = new QueryClient()

const App: FC = () => {
  const membersStore = useAccountsStore()
  const { isLoading, setIsLoading } = useGlobalLoaderStore()
  const [isCGUPopupOpen, setIsCGUPopupOpen] = useState(false)
  const [isNewFeaturePopupOpen, setIsNewFeaturePopupOpen] = useState(false)

  useEffect(() => {
    if (membersStore.connectedAccount && !membersStore.connectedAccount?.hasAcceptedTerms) {
      setIsCGUPopupOpen(true)
    }
  }, [membersStore.connectedAccount])

  useEffect(() => {
    if (
      membersStore.connectedAccount &&
      membersStore.connectedAccount?.hasAcceptedTerms &&
      !membersStore.connectedAccount?.hasSeenNewFeatures
    ) {
      setIsNewFeaturePopupOpen(true)
    }
  }, [membersStore.connectedAccount])

  useEffect(() => {
    AuthService.tryLogInWithLocalToken()
      .then((account) => {
        if (account) {
          membersStore.changeConnectedAccount(account)
          if (!account.hasAcceptedTerms) {
            setIsCGUPopupOpen(true)
          }
        }
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    setVh() // Set on initial load

    window.addEventListener('resize', setVh) // Set on resize

    // Cleanup function
    return () => {
      window.removeEventListener('resize', setVh) // Remove event listener on unmount
    }
  }, []) // Empty dependency array so effect only runs on mount and unmount

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CircularProgressPanel />
      </ThemeProvider>
    )
  }

  return (
    <MetricalpReactProvider
      initialSharedCustomProps={{
        screen_view: {
          customerId: membersStore.connectedAccount?.customerId ?? 'not-connected',
          customerName: membersStore?.connectedAccount?.id ?? 'not-connected',
        },
      }}
      tid="mam37"
    >
      <ScrollProvider>
        <CssBaseline />

        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100 * var(--vh))',
                maxHeight: 'calc(100 * var(--vh))',
                overflowY: 'hidden',
              }}
            >
              <Routes>
                <Route path="/" element={<PrivateRoutes />}>
                  <Route index element={<Navigate to={PATHS.animals.absolute} />} />

                  <Route path={PATHS.association.relative}>
                    <Route index element={<AssociationDetailsScreen />} />
                    <Route path="template" element={<EditTemplateDocument />} />
                    <Route path="signature" element={<SignatureScreen />} />
                    <Route path="zone" element={<SelectZonesScreen />} />
                    <Route path={PATHS.ajouterDonateur.relative} element={<AddDonatorScreen />} />
                  </Route>

                  <Route path={PATHS.carte.relative}>
                    <Route index element={<MapScreen />} />
                  </Route>

                  <Route path={PATHS.animals.relative}>
                    <Route index element={<AnimalListScreen />} />
                    <Route path={PATHS.ajouterAnimal.relative} element={<AddAnimalScreen />} />
                    <Route path={PATHS.animalDetails.relative} element={<AnimalDetailsScreen />} />
                    <Route path={PATHS.multiEdition.relative} element={<AnimalBulkEdit />} />
                  </Route>

                  <Route path={PATHS.suiviBenevole.relative}>
                    <Route index element={<ContactListScreen />} />
                    <Route path={PATHS.ajouterBenevole.relative} element={<AddMemberScreen />} />
                    <Route path={PATHS.accountDetails.relative} element={<MemberDetailsScreen />} />
                  </Route>

                  <Route path={PATHS.suiviVeterinaire.relative}>
                    <Route index element={<VeterinariesListScreen />} />
                    <Route path={PATHS.ajouterVeterinaire.relative} element={<AddMemberScreen />} />
                    <Route path={PATHS.veterinaryDetails.relative} element={<MemberDetailsScreen />} />
                  </Route>

                  <Route path={PATHS.suiviAdoptant.relative}>
                    <Route index element={<AdoptersListScreen />} />
                    <Route path={PATHS.ajouterAdoptant.relative} element={<AddAdopterScreen />} />
                    <Route path={PATHS.adoptantDetails.relative} element={<AdopterDetailsScreen />} />
                    <Route path={PATHS.assignerAnimal.relative} element={<AssignAnimalToAdopter />} />
                  </Route>
                </Route>
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin-login" element={<AdminLoginPage />} />
              </Routes>
            </Box>

            <CGUPopUp isOpen={isCGUPopupOpen} close={() => setIsCGUPopupOpen(false)} />
            <NewFeaturePopUp isOpen={isNewFeaturePopupOpen} close={() => setIsNewFeaturePopupOpen(false)} />

            <GlobalSnackBar />
          </QueryClientProvider>
        </ThemeProvider>
      </ScrollProvider>
    </MetricalpReactProvider>
  )
}

// window.onerror = function (message, url, lineNumber, columnNumber, error) {
//   ErrorsClient.notifyError({ message, url, lineNumber, currentUrl: window.location.href, error }).then(() => {
//     return true
//   })
// }

window.addEventListener('error', (event) => {
  ErrorsClient.notifyError({
    message: event.message,
    currentUrl: window.location.href,
    error: event.error.toString(),
    stack: event.error.stack,
  }).then(() => {
    return true
  })
})
export default App
