import React, { useEffect, useState } from 'react'
import {
  AdoptionAttemptDto,
  AdoptionAttemptDtoStatus,
  AnimalDto,
  AnimalInsightsDtoType,
} from '../../../interactors/gen/backendClient'
import { Card, CardContent, Box, Avatar, Typography, AvatarGroup, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { getAnimalProfilePhotoSrc } from '../../../utils/S3-links'
import { setOpacity } from '../../../utils/setOpacity'
import { blueSky, colors } from '../../theme'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { getReadableSpecies } from '../../../domain/Animal/AnimalDisplay'
import { getAdoptionAttemptStatus } from '../../../utils/getAdoptionAttemptStatus'
import { CircleWithNumber, HtmlTooltip } from '../../Animals/AnimalsTableItem'
import { InfoBox } from '../../common/InfoBox'

interface Props {
  adoptionAttempt: AdoptionAttemptDto
}

export const OnGoingAdoptionItem: React.FC<Props> = ({ adoptionAttempt }) => {
  const [animal, setAnimal] = useState<AnimalDto | undefined>(undefined)
  const animalId = adoptionAttempt.animalId
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search)
  const tab = queryParams.get('tab')

  // Fetch animal data
  useEffect(() => {
    const fetchAnimal = async () => {
      const animal = await AnimalsClient.getAnimal(animalId)
      if (animal) {
        setAnimal(animal)
      }
    }

    fetchAnimal()
  }, [animalId])

  const handleCopyLink = () => {
    if (adoptionAttempt.paymentLink) {
      navigator.clipboard.writeText(adoptionAttempt.paymentLink)
      alert('Lien de paiement copié dans le presse-papiers.')
    }
  }

  const profileImageKey = animal?.images?.profileImageKey
  const animalProperties = [
    animal?.species && getReadableSpecies(animal?.species),
    animal?.health.sterilizedOrNeutered === 'true' ? 'Stérilisé' : 'Non stérilisé',
  ]

  const order: Array<AnimalInsightsDtoType> = ['info', 'error', 'warning']
  const insights = adoptionAttempt.insights || []

  return (
    <Card sx={{ mb: 6, cursor: 'pointer' }} onClick={() => navigate(`./?tab=${tab}&attemptId=${adoptionAttempt.id}`)}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {/* Animal Details */}
          {animal && (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
              <Avatar
                alt={animal.name}
                src={profileImageKey && getAnimalProfilePhotoSrc(profileImageKey)}
                sx={{ width: 64, height: 64 }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <Typography fontSize={20} color="text.secondary" fontWeight={700}>
                  {animal?.name}
                </Typography>
                <Typography fontSize={15} color="text.secondary">
                  {animalProperties.filter(Boolean).join(', ')}
                </Typography>
              </Box>
              <HtmlTooltip
                onClick={(e) => e.stopPropagation()}
                title={
                  <React.Fragment>
                    {insights
                      .sort((a, b) => {
                        const indexA = order.indexOf(a.type)
                        const indexB = order.indexOf(b.type)
                        return indexA - indexB
                      })
                      .map((insight, index) => (
                        <InfoBox key={index} messageType={insight.type} content={insight.message} sx={{ mb: 1 }} />
                      ))}
                  </React.Fragment>
                }
              >
                <AvatarGroup>
                  {insights.length !== 0 ? (
                    <CircleWithNumber messageType="warning" number={insights.length} />
                  ) : (
                    <CircleWithNumber messageType="disabled" />
                  )}
                </AvatarGroup>
              </HtmlTooltip>
              <Link
                to={`/animaux/${animal?.id}`}
                style={{ fontWeight: 700, color: blueSky }}
                onClick={(e) => e.stopPropagation()}
              >
                Profil de l&#39;animal
              </Link>
            </Box>
          )}

          {/* Copy Link Button */}
          {adoptionAttempt.paymentLink && !adoptionAttempt.paymentDone && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation()
                handleCopyLink()
              }}
              sx={{
                borderRadius: 8,
                textTransform: 'none',
                fontWeight: 'bold',
                padding: '8px 16px',
              }}
            >
              Copier le lien de paiement
            </Button>
          )}

          {/* Adoption Status and Copy Link */}
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={15} color="text.secondary" fontWeight={700}>
                Statut d&#39;adoption
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 2,
                  width: '100%',
                  maxWidth: '380px',
                  px: 2,
                  py: 2,
                  backgroundColor:
                    adoptionAttempt.status === AdoptionAttemptDtoStatus.SuspendedByCustomer ||
                    adoptionAttempt.status === AdoptionAttemptDtoStatus.SuspendedByUser
                      ? setOpacity(colors.red, 0.9)
                      : setOpacity(colors.amber, 0.9),
                }}
              >
                <Typography fontSize={15} fontWeight={700} color="white">
                  {getAdoptionAttemptStatus(adoptionAttempt)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
