import { Box, Card, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { colors, theme } from '../../theme'
import { EssentialOffer } from './EssentialOffer'
import { UsefulOffer } from './UsefulOffer'

export const FunctionalitiesTab = () => {
  /* const accountsStore = useAccountsStore()
  const membersCount = accountsStore.members.filter(
    (member) => member.permission !== AccountDtoPermission.Silent
  ).length */
  const globalSnackarStore = useGlobalSnackbarStore()
  const queryParams = new URLSearchParams(window.location.search)
  const [customer, setCustomer] = useState<CustomerDto | null>(null)

  /* const isCustomerSubscribed = customer?.subscription?.status === 'active' */

  const maxMembers = 10

  const fetchCustomerMutation = useMutation(async () => await CustomersClient.getCurrentCustomer(), {
    onSuccess: (data) => {
      setCustomer(data || null)
    },
  })

  useEffect(() => {
    fetchCustomerMutation.mutate()
  }, [])

  useEffect(() => {
    if (queryParams.get('success')) {
      globalSnackarStore.triggerSuccessMessage('Félicitations ! Vous êtes maintenant abonné à Petso')
    } else if (queryParams.get('canceled')) {
      globalSnackarStore.triggerErrorMessage('Votre paiement a échoué. Veuillez rééssayer.')
    }
  }, [])
  return (
    <>
      <Box sx={{ padding: 2, backgroundColor: 'grey.100', minHeight: '100%' }}>
        <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 2 }}>
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              fontSize: '22px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              pl: 2,
              pr: 10,
              mb: 4,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
            }}
          >
            Abonnement
          </Box>
          <Box sx={{ maxWidth: '1050px', marginX: 'auto' }}>
            <Card
              sx={{
                borderRadius: 3,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 1.5,
                px: 4,
                gap: 2,
                alignItems: 'center',
                backgroundColor: '#F7F7F9',
              }}
            >
              <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.success.main,
                    fontSize: 24,
                    fontWeight: 'bold',
                  }}
                >
                  Gratuit
                </Typography>
                <Typography variant="subtitle1" sx={{ color: colors.cello, fontWeight: 'bold', fontSize: 16 }}>
                  Configuration d’un espace - {maxMembers} licences d’accès
                </Typography>

                {/* <Box>
                  <LinearProgress
                    variant="determinate"
                    value={(2 / 3) * 100}
                    sx={{
                      height: 30,
                      borderRadius: 5,
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: '5px 0 0 5px',
                        backgroundColor: theme.palette.success.main,
                      },
                    }}
                  />
                </Box>
                <Typography sx={{ color: colors.cello, fontSize: 14, textAlign: 'center' }}>
                  2/3 frais d’adoption en ligne via Petso ce mois <br />
                  ou <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Payer 6 euros par crédit</span>
                </Typography>*/}
              </Box>

              <Box
                sx={{
                  flex: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: 'black',
                    fontSize: 13,
                  }}
                >
                  <b>
                    Depuis 2 ans, notre outil est gratuit pour soutenir toutes les associations et rester accessible au
                    plus grand nombre.
                  </b>
                  <br />
                  Vous pouvez participer à notre développement en partageant nos liens de règlement d’adoption avec les
                  adoptants. Grâce à leur soutien, une commission solidaire de 12 € nous est reversée pour continuer à
                  vous accompagner.
                </Typography>
              </Box>
            </Card>
            <Grid container spacing={2} sx={{ marginTop: 2, justifyContent: 'space-between' }}>
              <Grid item xs={12} sm={5.6}>
                <EssentialOffer customer={customer} />
              </Grid>
              <Grid item xs={12} sm={5.6}>
                <UsefulOffer customer={customer} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}
