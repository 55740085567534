import React, { useEffect } from 'react'
import { AnimalInsightsDtoType, AnimalSearchItemDto } from '../../interactors/gen/backendClient'
import { Avatar, AvatarGroup, Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { getEmojiSpecies, getReadableIdentificationNumber } from '../../domain/Animal/AnimalDisplay'
import {
  // AnimalInsightsAvatarGroup,
  AnimalSexIcon,
  AnimalStatusChip,
  backgroundShouldBeGrey,
  CircleWithNumber,
  HtmlTooltip,
} from './AnimalsTableItem'
import { fullName } from '../../domain/Account/AccountDisplay'
import { InfoBox } from '../common/InfoBox'
import { colors, lightGrey } from '../theme'
import { setOpacity } from '../../utils/setOpacity'
import dayjs from 'dayjs'

type CardProps = {
  animal: AnimalSearchItemDto
  myAnimalsSection?: boolean
  assignAnimalId?: React.Dispatch<React.SetStateAction<string | null>>
  selectedAnimalId?: string
  index: number
  expandedCardIndex: number | null
  setExpandedCardIndex: React.Dispatch<React.SetStateAction<number | null>>
  openedTooltip: boolean
  setOpenedTooltip: React.Dispatch<React.SetStateAction<boolean>>
}
const AnimalsListCard: React.FC<CardProps> = ({
  animal,
  myAnimalsSection = false,
  assignAnimalId,
  selectedAnimalId,
  index,
  expandedCardIndex,
  setExpandedCardIndex,
  openedTooltip,
  setOpenedTooltip,
}) => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    if (assignAnimalId) {
      assignAnimalId(animal.id)
      return
    }
    return navigate(`/animaux/${animal.id}`)
  }
  const handleOpen = (event: React.SyntheticEvent<Element, Event>) => {
    event.stopPropagation()
    setOpen(true)
    setExpandedCardIndex(index)
    setOpenedTooltip(true)
  }
  const handleClose = (event: Event | React.SyntheticEvent<Element, Event>) => {
    event.stopPropagation()
    setOpen(false)
    setExpandedCardIndex(index)
    setOpenedTooltip(false)
  }

  useEffect(() => {
    if (index !== expandedCardIndex) {
      setOpen(false)
    }
  }, [expandedCardIndex])

  const profileImageKey = animal.images?.profileImageKey
  const { responsibilities } = animal
  const order: Array<AnimalInsightsDtoType> = ['info', 'error', 'warning']

  return (
    <Card
      onClick={
        openedTooltip
          ? (event) => {
              handleClose(event)
            }
          : handleClick
      }
      sx={{
        borderRadius: '25px',
        border: '2px solid #D2D2D2',
        backgroundColor:
          selectedAnimalId === animal.id
            ? setOpacity(colors.bermudaGray, 0.35)
            : backgroundShouldBeGrey(animal, myAnimalsSection)
            ? lightGrey
            : '',
        boxShadow: 'none',
      }}
    >
      <CardContent
        sx={{
          paddingTop: 0,
          '&:last-child': { paddingBottom: 0 },
          paddingLeft: '25px',
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        {/* Box 1: Avatar, [name, id, birthdate] */}
        <Box display="flex" gap={2} paddingTop={2}>
          {/* Avatar */}
          <Avatar
            alt={animal.name}
            src={profileImageKey ? getAnimalProfilePhotoSrc(profileImageKey) : ''}
            sx={{
              height: '80px',
              width: '80px',
            }}
          />
          {/* Name, id, birthdate */}
          <Box>
            <Box display="flex" gap={1} alignItems="center">
              <Typography fontWeight={700}>{animal.name} </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{
                    fontSize: 22,
                  }}
                >
                  {getEmojiSpecies(animal.species)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    paddingTop: '5px',
                  }}
                >
                  {animal.sex && <AnimalSexIcon sex={animal.sex} />}
                </Typography>
              </Box>
            </Box>
            <Typography>
              {animal.identificationNumber
                ? getReadableIdentificationNumber(animal.identificationNumber?.toString())
                : ''}
            </Typography>
            {animal.birthdate && (
              <Typography fontWeight={700} color="secondary">
                {`Né${animal.sex === 'female' ? 'e' : ''} le ${dayjs(animal.birthdate).format('DD/MM/YYYY')}`}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Box 2: Status, Insights tooltip */}
        <Box display="flex" flexDirection="row" gap="15px">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontSize="15px" sx={{ textDecoration: 'underline' }}>
              Statut
            </Typography>
            <AnimalStatusChip animal={animal} />
          </Box>
          <Box
            onClick={(event) => {
              event.stopPropagation()
            }}
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="flex-start"
          >
            <Typography fontSize="15px" sx={{ textDecoration: 'underline', width: '100%' }}>
              Rappels
            </Typography>
            <HtmlTooltip
              sx={{
                paddingX: `calc((100vw - 300px)/2)`,
              }}
              title={
                <React.Fragment>
                  <Box display="flex" justifyContent="flex-end" sx={{}}>
                    <IconButton
                      sx={{
                        paddingY: 0,
                      }}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {animal.insights
                    .sort((a, b) => {
                      const typeA = a.type
                      const typeB = b.type

                      // Get the index of each type in the order array
                      const indexA = order.indexOf(typeA)
                      const indexB = order.indexOf(typeB)

                      // Compare the indexes to determine the sorting order
                      return indexA - indexB
                    })
                    .map((insight, index) => {
                      // eslint-disable-next-line react/jsx-key
                      return (
                        <InfoBox
                          key={index}
                          messageType={insight.type}
                          content={insight.message}
                          sx={{ marginBottom: 1 }}
                        />
                      )
                    })}
                </React.Fragment>
              }
              open={open}
            >
              <AvatarGroup onClick={open ? handleClose : handleOpen}>
                {animal.insightsCounters.info !== 0 && (
                  <CircleWithNumber messageType="info" number={animal.insightsCounters.info} />
                )}
                {animal.insightsCounters.warning !== 0 && (
                  <CircleWithNumber messageType="warning" number={animal.insightsCounters.warning} />
                )}
                {animal.insightsCounters.error !== 0 && (
                  <CircleWithNumber messageType="error" number={animal.insightsCounters.error} />
                  // <Avatar alt={`${animal.insightsCounters.error}`} sx={{ backgroundColor: 'main.theme.palette.error.main' }} />
                )}
                {animal.insightsCounters.success !== 0 && (
                  <CircleWithNumber messageType="success" number={animal.insightsCounters.success} />
                )}

                {animal.insightsCounters.error === 0 &&
                  animal.insightsCounters.warning === 0 &&
                  animal.insightsCounters.info === 0 &&
                  animal.insightsCounters.success === 0 && (
                    <CircleWithNumber messageType="disabled" />
                    // <Avatar alt={`${animal.insightsCounters.error}`} sx={{ backgroundColor: 'main.theme.palette.error.main' }} />
                  )}
              </AvatarGroup>
            </HtmlTooltip>
          </Box>
        </Box>

        {/* Box 3 : Member in charge, host family, cage number*/}
        <Box paddingBottom={2} fontSize="15px">
          <Typography sx={{ textDecoration: 'underline' }}>
            {responsibilities?.memberInChargeAccount || responsibilities?.hostFamilyInChargeAccount
              ? 'Responsable : '
              : animal.responsibilities?.cageNumber && 'Responsable : '}
          </Typography>
          <Typography fontSize="15px" fontStyle="italic">
            {responsibilities?.memberInChargeAccount &&
              `Référent : ${fullName(responsibilities?.memberInChargeAccount)}`}
          </Typography>
          <Typography fontSize="15px" fontStyle="italic">
            {responsibilities?.hostFamilyInChargeAccount
              ? `FA : ${fullName(responsibilities?.hostFamilyInChargeAccount)}`
              : animal.responsibilities?.cageNumber && `Box N°${animal.responsibilities?.cageNumber}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default AnimalsListCard
