import React from 'react'
import { AdopterDto, AdopterDtoProfileHasBeenAccepted } from '../../interactors/gen/backendClient'
import { Edit } from '@mui/icons-material'
import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  TextField,
  FormControl,
  DialogActions,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { Controller } from 'react-hook-form'
import { fullName } from '../../domain/Account/AccountDisplay'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { MemberPromptSaveButton } from '../Members/MemberPromptSaveButton'
import { spacingItem } from '../theme'
import { ColoredChipSelectField } from '../common/ColoredChipSelectField'
import {
  getReadableAdopterProfileHasBeenAccepted,
  getColorForAdopterProfileHasBeenAccepted,
} from '../../domain/Adopters/AccountDisplay'
import { ControlledSelectField } from '../common/ControlledSelectField'

interface Props {
  adopter: AdopterDto
  isOwnAccount: boolean
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
  isLoading: boolean
}

export interface MemberFormParams {
  civility: AdopterDto['civility']
  firstName: AdopterDto['firstName']
  lastName: AdopterDto['lastName']
  email: AdopterDto['email']
  phoneNumber: AdopterDto['phoneNumber']
  secondPhoneNumber: AdopterDto['secondPhoneNumber']
  city: AdopterDto['city']
  address: AdopterDto['address']
  postalCode: AdopterDto['postalCode']
  country: AdopterDto['country']
  nationality: AdopterDto['nationality']
  profileHasBeenAccepted: AdopterDto['profileHasBeenAccepted']
}

export const EditAdopterGeneralInfoPrompt: React.FC<Props> = ({ adopter, isOwnAccount, onSubmit, isLoading }) => {
  const dialogOpenedState = useDialogOpened()
  const isMobile = useMediaQuery('(max-width:600px)')

  const getDefaultValues = (adopter: AdopterDto | null): MemberFormParams => ({
    civility: adopter?.civility || '',
    firstName: adopter?.firstName || '',
    lastName: adopter?.lastName || '',
    email: adopter?.email || '',
    phoneNumber: adopter?.phoneNumber || '',
    secondPhoneNumber: adopter?.secondPhoneNumber || '',
    address: adopter?.address || '',
    postalCode: adopter?.postalCode || '',
    country: adopter?.country || '',
    nationality: adopter?.nationality || '',
    city: adopter?.city || '',
    profileHasBeenAccepted: adopter?.profileHasBeenAccepted,
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(adopter, getDefaultValues)
  return (
    <>
      <IconButton
        color="primary"
        onClick={dialogOpenedState.openDialog}
        sx={{
          // border: '1px solid blue',
          width: isMobile ? '30px' : 'auto',
          height: isMobile ? '30px' : 'auto',
        }}
      >
        <Tooltip title="Editer le profil">
          <Edit />
        </Tooltip>
      </IconButton>

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>Editer le profil de {adopter ? fullName(adopter) : '..'}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}>
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <ColoredChipSelectField
                control={control}
                fieldName="profileHasBeenAccepted"
                label="Statut du profil"
                error={errors.profileHasBeenAccepted}
                options={Object.values(AdopterDtoProfileHasBeenAccepted)
                  .filter((value) => ['accepted', 'waiting', 'blacklisted', 'refused'].includes(value))
                  .map((profileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted) => ({
                    label: getReadableAdopterProfileHasBeenAccepted(profileHasBeenAccepted),
                    value: profileHasBeenAccepted,
                    color: getColorForAdopterProfileHasBeenAccepted(profileHasBeenAccepted),
                  }))}
                requiredRule=""
                sx={spacingItem}
              />

              <ControlledSelectField
                control={control}
                fieldName="civility"
                label="Civilité"
                options={[
                  { label: 'M.', value: 'M.' },
                  { label: 'Mme.', value: 'Mme.' },
                  { label: '-', value: '-' },
                ]}
                sx={spacingItem}
                error={undefined}
                requiredRule=""
              />

              <TextField
                id="first-name-input"
                label="Prénom"
                type="text"
                required
                fullWidth
                {...register('firstName', { required: 'Le prénom est requis' })}
                sx={spacingItem}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                id="last-name-input"
                label="Nom de Famille"
                type="text"
                fullWidth
                {...register('lastName')}
                sx={spacingItem}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                id="email"
                label="Email"
                required
                type="email"
                fullWidth
                {...register('email', {
                  required: 'required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "L'addresse n'est pas valide.",
                  },
                })}
                sx={spacingItem}
                error={!!errors.email}
                helperText={errors.email?.message}
                // helperText="Vous ne pouvez pas encore modifier cette information"
                InputLabelProps={{ shrink: true }}
              />

              <FormControl fullWidth variant="outlined" sx={spacingItem}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: (value: string | undefined) => {
                      console.log(value)
                      return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                        ? true
                        : 'Tel is invalid'
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      {...field}
                      label="Numero de Téléphone"
                      defaultCountry="FR"
                      helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={spacingItem}>
                <Controller
                  name="secondPhoneNumber"
                  control={control}
                  rules={{
                    validate: (value: string | undefined) => {
                      console.log(value)
                      return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                        ? true
                        : 'Tel is invalid'
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      {...field}
                      label="Second numéro de Téléphone"
                      defaultCountry="FR"
                      helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                      error={!!fieldState.error}
                    />
                  )}
                />
              </FormControl>

              <TextField
                id="address"
                label="Adresse"
                type="text"
                fullWidth
                {...register('address')}
                sx={spacingItem}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                id="postalCode"
                label="Code Postal"
                type="text"
                fullWidth
                {...register('postalCode')}
                InputLabelProps={{ shrink: true }}
                sx={spacingItem}
              />

              <TextField
                id="city"
                label="Ville"
                type="text"
                fullWidth
                {...register('city')}
                sx={spacingItem}
                error={!!errors.city}
                InputLabelProps={{ shrink: true }}
                helperText={errors.city?.message}
              />

              <TextField
                id="country"
                label="Pays"
                type="text"
                fullWidth
                {...register('country')}
                sx={spacingItem}
                error={!!errors.country}
                InputLabelProps={{ shrink: true }}
                helperText={errors.country?.message}
              />

              <TextField
                id="nationality"
                label="Nationalité"
                type="text"
                fullWidth
                {...register('nationality')}
                sx={spacingItem}
                error={!!errors.nationality}
                InputLabelProps={{ shrink: true }}
                helperText={errors.nationality?.message}
              />
            </DialogContent>

            <DialogActions>
              <MemberPromptSaveButton
                isOwnAccount={isOwnAccount}
                isLoading={isLoading}
                dialogOpenedState={dialogOpenedState}
              />
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
