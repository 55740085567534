import { Edit } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import { omit } from 'lodash'
import {
  getReadableAnimalBreedTitle,
  getReadableIdentificationNumberLocation,
  getReadableSpecies,
  getReadableSterilizedForFerrets,
  getReadableTattooLocation,
} from '../../../domain/Animal/AnimalDisplay'
import {
  AnimalDto,
  AnimalDtoIdentificationNumberLocation,
  AnimalDtoSpecies,
  AnimalHealthDtoSterilizedForFerrets,
  AnimalHealthDtoSterilizedOrNeutered,
  AnimalHealthOperationDto,
  TattooDtoLocation,
} from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useEditAnimalMutation } from '../../../store/useEditAnimalMutation'
import { readableAxiosError } from '../../../utils/axios'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { getReadableBooleanOrUnknown } from '../../../utils/getReadableBooleanOrUnknown'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { omitEmptyString } from '../../../utils/omitEmptyString'
import { ControlledDateField } from '../../common/ControlledDateField'
import { ControlledSelectField } from '../../common/ControlledSelectField'

import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'

// We need to omit, if not it does not work
type FormParams = {
  name: AnimalDto['name']
  species: AnimalDto['species']
  sex: AnimalDto['sex']
  sterilizedOrNeutered: AnimalHealthDtoSterilizedOrNeutered | string | ''
  sterilizedForFerrets?: AnimalHealthDtoSterilizedForFerrets
  sterilizedOrNeuteredDate?: string
  identificationNumber: string | ''
  identificationNumberLocation: AnimalDtoIdentificationNumberLocation | undefined
  birthdayDate: string
  breed: string
  hair: string
  tattooLocation?: string
  tattooNumber?: string
  // 1 = true, 2 = false --- Needed because Select component does not support boolean
  isApproximateBirthdayDate?: number
}

interface Props {
  animal: AnimalDto
  menuItem?: boolean
}

export const EditGeneralInfoAnimalPrompt: FC<Props> = ({ animal, menuItem = false }) => {
  const accountsStore = useAccountsStore()
  const dialogOpenedState = useDialogOpened()

  const getDefaultValues = (animal: AnimalDto): FormParams => ({
    name: animal.name,
    species: animal.species,
    sex: animal.sex,
    sterilizedOrNeutered: animal.health.sterilizedOrNeutered ? animal.health.sterilizedOrNeutered : 'unknown',
    sterilizedOrNeuteredDate: animal.health.operations?.find((operation) =>
      operation.type.includes('sterilized-or-neutered')
    )?.date,
    identificationNumber: animal.identificationNumber || '',
    identificationNumberLocation: animal.identificationNumberLocation || undefined,
    birthdayDate: animal.birthday?.date || '',
    breed: animal.breed || '',
    hair: animal.hair || '',
    tattooLocation: animal?.tattoo?.location || '',
    tattooNumber: animal?.tattoo?.number || '',
    isApproximateBirthdayDate: animal.birthday?.isApproximate ? 1 : 2,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useFormExtended(animal, getDefaultValues)

  const [serverError, setServerError] = useState('')

  const editAnimalMutation = useEditAnimalMutation({
    onSuccess: dialogOpenedState.closeDialog,
    onError: (error) => {
      setServerError(readableAxiosError(error).join(' '))
    },
  })

  const handleSterilizationInfoSubmit = (
    sterilizedOrNeutered: AnimalHealthDtoSterilizedOrNeutered,
    date?: string
  ): AnimalHealthOperationDto[] => {
    const operations = animal.health.operations
    const operation = operations.find((operation) => operation.type.includes('sterilized-or-neutered'))

    if (date === animal.health.operations?.find((operation) => operation.type.includes('sterilized-or-neutered'))?.date)
      return operations

    if (operation) {
      const operationIndex = operations.indexOf(operation)
      if (sterilizedOrNeutered !== 'true') {
        if (operation.type.length > 1) {
          operations[operationIndex].type = operations[operationIndex].type.filter(
            (type) => type !== 'sterilized-or-neutered'
          )
        } else {
          operations.splice(operationIndex, 1)
        }
      } else if (date && operation.date !== dayjs(date).format('DD/MM/YYYY')) {
        operations[operationIndex].date = dayjs(date).format('DD/MM/YYYY')
      }
    } else if (sterilizedOrNeutered === 'true') {
      operations.push({
        id: nanoid(),
        type: ['sterilized-or-neutered'],
        date: date ? dayjs(date).format('DD/MM/YYYY') : '',
      })
    }

    return operations
  }

  const onSubmit = async (data: FormParams) => {
    console.log('On submit', data)

    if (data.sterilizedForFerrets) {
      switch (data.sterilizedForFerrets) {
        case 'implanted':
          data.sterilizedOrNeutered = 'true'
          break
        case 'sterilized':
          data.sterilizedOrNeutered = 'true'
          break
        case 'not-sterilized':
          data.sterilizedOrNeutered = 'false'
          break
        case 'unknown':
          data.sterilizedOrNeutered = 'unknown'
          break
        default:
          break
      }
    }

    const newAnimal = {
      ...animal,
      ...omit(
        data,
        'birthdayDate',
        'isApproximateBirthdayDate',
        'adoptionStatus',
        'sterilizedOrNeutered',
        'sterilizedOrNeuteredDate',
        'tattooLocation',
        'tattooNumber'
      ),
      birthday: {
        date: data.birthdayDate,
        isApproximate: data.isApproximateBirthdayDate
          ? data.isApproximateBirthdayDate === 1
            ? true
            : false
          : undefined,
      },
      health: {
        ...animal.health,
        sterilizedOrNeutered: data.sterilizedOrNeutered as AnimalHealthDtoSterilizedOrNeutered,
        sterilizedForFerrets: data.sterilizedForFerrets,
        operations: handleSterilizationInfoSubmit(
          data.sterilizedOrNeutered as AnimalHealthDtoSterilizedOrNeutered,
          data.sterilizedOrNeuteredDate
        ),
      },
      tattoo: {
        location: data.tattooLocation as TattooDtoLocation,
        number: data.tattooNumber,
      },
    }

    const newAnimalWithoutEmptyString = omitEmptyString(newAnimal)

    await editAnimalMutation.mutate(newAnimalWithoutEmptyString)
  }

  const species = accountsStore.connectedCustomer?.preferences.species
  useEffect(() => {
    if (species?.length === 1) {
      setValue('species', species[0])
    }
  }, [species])
  const spacingItem = { marginTop: 2 }

  return (
    <>
      {menuItem ? (
        <MenuItem onClick={dialogOpenedState.openDialog}>
          <ListItemText primary="Editer le profil" />
        </MenuItem>
      ) : (
        <IconButton color="primary" onClick={dialogOpenedState.openDialog}>
          <Tooltip title="Editer le profil">
            <Edit />
          </Tooltip>
        </IconButton>
      )}

      <Dialog
        maxWidth="sm"
        open={dialogOpenedState.isDialogOpen}
        onClose={dialogOpenedState.closeDialog}
        fullWidth
        scroll="paper"
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
      >
        <DialogTitle>🐈 Editer le profil de {animal.name}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', maxHeight: '100%', boxSizing: 'content-box' }}>
          <Box
            sx={{
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxSizing: 'content-box',
            }}
          >
            <DialogContent sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)' }}>
              <TextField
                id="first-name-input"
                label="Nom"
                type="text"
                required
                fullWidth
                {...register('name', { required: 'Le nom est requis' })}
                sx={spacingItem}
                error={!!errors.name}
                helperText={errors.name?.message}
              />

              <ControlledSelectField
                sx={spacingItem}
                control={control}
                error={errors.species}
                fieldName="species"
                label="Espèce"
                options={
                  species
                    ? Object.values(species).map((status: AnimalDtoSpecies) => ({
                        label: getReadableSpecies(status, { withEmoji: true }),
                        value: status,
                      }))
                    : []
                }
                requiredRule="L'espèce est requise."
                disabled={species?.length === 1}
              />

              <ControlledSelectField
                sx={spacingItem}
                control={control}
                error={errors.sex}
                fieldName="sex"
                label="Sexe"
                options={[
                  { label: 'Mâle', value: 'male' },
                  { label: 'Femelle', value: 'female' },
                ]}
                requiredRule="Le sexe est requis"
              />

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {animal.species === 'ferret' ? (
                    <ControlledSelectField
                      sx={spacingItem}
                      control={control}
                      error={errors.sterilizedForFerrets}
                      fieldName="sterilizedForFerrets"
                      label={'Stérilisation'}
                      options={Object.values(AnimalHealthDtoSterilizedForFerrets).map(
                        (value: AnimalHealthDtoSterilizedForFerrets) => ({
                          label: getReadableSterilizedForFerrets(value),
                          value: value,
                        })
                      )}
                      requiredRule={undefined}
                    />
                  ) : (
                    <ControlledSelectField
                      sx={spacingItem}
                      control={control}
                      error={errors.sterilizedOrNeutered}
                      fieldName="sterilizedOrNeutered"
                      label={animal.sex === 'male' ? 'Castré' : 'Stérilisée'}
                      options={Object.values(AnimalHealthDtoSterilizedOrNeutered).map(
                        (value: AnimalHealthDtoSterilizedOrNeutered) => ({
                          label: getReadableBooleanOrUnknown(value),
                          value: value,
                        })
                      )}
                      requiredRule={undefined}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <ControlledDateField
                    sx={spacingItem}
                    control={control}
                    validate={(value) => isValidPastDateCoherence(value) || !value}
                    error={errors.sterilizedOrNeuteredDate}
                    fieldName={'sterilizedOrNeuteredDate'}
                    label={animal.sex === 'male' ? 'Date de castration' : 'Date de stérilisation'}
                    requiredRule={undefined}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="identification-number-input"
                    label="Identification"
                    type="text"
                    fullWidth
                    {...register('identificationNumber')}
                    sx={spacingItem}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledSelectField
                    sx={spacingItem}
                    control={control}
                    error={errors.identificationNumberLocation}
                    fieldName="identificationNumberLocation"
                    label="Emplacement de l'identification"
                    options={Object.values(AnimalDtoIdentificationNumberLocation).map(
                      (value: AnimalDtoIdentificationNumberLocation) => ({
                        label: getReadableIdentificationNumberLocation(value),
                        value: value,
                      })
                    )}
                    requiredRule={undefined}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="tattoo-number-input"
                    label="Tatouage"
                    type="text"
                    fullWidth
                    {...register('tattooNumber')}
                    sx={spacingItem}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledSelectField
                    sx={spacingItem}
                    control={control}
                    error={errors.tattooLocation}
                    fieldName="tattooLocation"
                    label="Emplacement du tatouage"
                    options={Object.values(TattooDtoLocation).map((value: TattooDtoLocation) => ({
                      label: getReadableTattooLocation(value),
                      value: value,
                    }))}
                    requiredRule={undefined}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledDateField
                    sx={spacingItem}
                    control={control}
                    validate={(value) => isValidPastDateCoherence(value) || !value}
                    error={errors.sex}
                    fieldName={'birthdayDate'}
                    label="Date de Naissance"
                    requiredRule={undefined}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledSelectField
                    sx={spacingItem}
                    control={control}
                    error={errors.isApproximateBirthdayDate}
                    fieldName="isApproximateBirthdayDate"
                    label="Est-elle exacte ?"
                    options={[
                      { label: 'Non', value: 1 },
                      { label: 'Oui', value: 2 },
                    ]}
                    requiredRule="Vous devez préciser si la date de naissance est précise ou non."
                  />
                </Grid>
              </Grid>

              <TextField
                id="breed-input"
                label={
                  animal.breedData?.breedTitle
                    ? getReadableAnimalBreedTitle(animal.breedData?.breedTitle)
                    : 'Type de race'
                }
                type="text"
                fullWidth
                {...register('breed')}
                sx={{ ...spacingItem, cursor: 'disabled', input: { cursor: 'not-allowed' } }}
                inputProps={{ readOnly: true }}
                helperText="Pour modifier la race, allez en bas de page dans la partie portée et généalogie"
              />

              {serverError && (
                <Box sx={spacingItem}>
                  <Typography color="error">{serverError}</Typography>
                </Box>
              )}
            </DialogContent>

            <DialogActions>
              <LoadingButton
                variant="contained"
                sx={{ width: '100%' }}
                type="submit"
                loading={editAnimalMutation.isLoading}
              >
                Mettre à jour
              </LoadingButton>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </>
  )
}
