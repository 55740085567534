import { FC, useState } from 'react'
import { AnimalDto, AnimalInsightsDto, AnimalInsightsDtoType } from '../../../interactors/gen/backendClient'
import { Box } from '@mui/material'
import { InfoBox } from '../../common/InfoBox'

interface Props {
  animal: AnimalDto
}

export const AllAnimalInsightsInfoBox: FC<Props> = ({ animal }) => {
  const [collapsedTypes, setCollapsedTypes] = useState<Record<AnimalInsightsDtoType, boolean>>({
    info: true,
    error: true,
    warning: true,
    success: true,
  })

  const order: Array<AnimalInsightsDtoType> = ['info', 'error', 'warning', 'success']

  const groupedInsights = animal.insights.reduce<Record<AnimalInsightsDtoType, AnimalInsightsDto[]>>((acc, insight) => {
    const { type } = insight
    if (!acc[type]) {
      acc[type] = []
    }
    acc[type].push(insight)
    return acc
  }, {} as Record<AnimalInsightsDtoType, AnimalInsightsDto[]>)

  const toggleCollapse = (type: AnimalInsightsDtoType) => {
    setCollapsedTypes((prev) => ({
      ...prev,
      [type]: !prev[type],
    }))
  }

  return (
    <Box sx={{ marginBottom: 1, marginTop: 1.5 }}>
      {order.map((type) => {
        const insights = groupedInsights[type] || []
        if (insights.length === 0) {
          return null
        }

        const isCollapsed = collapsedTypes[type]
        const visibleInsights = isCollapsed ? [insights[0]] : insights

        return (
          <Box key={type} sx={{ marginBottom: 2 }}>
            {visibleInsights.map((insight, index) => (
              <InfoBox
                key={`${type}-${index}`}
                messageType={type}
                content={insight.message}
                isCollapsed={isCollapsed && index === 0}
                hiddenCount={isCollapsed ? insights.length - 1 : 0}
                onToggleCollapse={() => toggleCollapse(type)}
                hasCollapseButton={insights.length > 1}
              />
            ))}
          </Box>
        )
      })}
    </Box>
  )
}
