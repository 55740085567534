import { InfoOutlined } from '@mui/icons-material'
import { Box, Chip, SxProps, Theme, Tooltip, Typography } from '@mui/material'

import { FC } from 'react'
import { isColorDark } from '../../utils/isColorDark'

export interface ReadOnlyItemProps {
  /**
   * The class applied to the root element.
   */
  className?: string
  /**
   * The label of the item.
   */
  label: string
  /**
   * If `true`, the value can be shown in multiple lines.
   */
  multiline?: boolean
  /**
   * The value of the item.
   */
  value: string
  /**
   * The text to be displayed in the tooltip. If left empty or undefined, the info icon will not be displayed.
   */
  tooltip?: string

  sx?: SxProps<Theme>

  size?: 'medium' | 'small'

  chipColor?: string
}

export const ReadOnlyChip: FC<ReadOnlyItemProps> = ({
  className,
  label,
  value,
  tooltip,
  multiline,
  sx,
  size,
  chipColor,
}) => {
  return (
    <Box className={className} sx={sx}>
      <Box display="flex" alignItems="center" sx={{ marginBottom: 1 }}>
        <Typography
          variant={size === 'small' ? 'caption' : 'body2'}
          color="textSecondary"
          sx={{
            lineHeight: '0.875rem',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textDecoration: 'none',
          }}
        >
          {label}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="right">
            <InfoOutlined color="primary" sx={{ marginLeft: 3, width: 14, height: 14 }} />
          </Tooltip>
        )}
      </Box>
      <Chip
        sx={{
          color: isColorDark(chipColor || '#fff') ? '#fff' : '#000',
          backgroundColor: chipColor,
          whiteSpace: multiline ? 'pre-wrap' : 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        label={value}
      />
    </Box>
  )
}
