import { Box, Button, IconButton, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'

import { ExpandLess } from '@mui/icons-material'
import { setOpacity } from '../../utils/setOpacity'

export type MessageType = 'warning' | 'error' | 'info' | 'success'

interface InfoBoxProps {
  content: string
  messageType: MessageType
  sx?: SxProps<Theme>
  isCollapsed?: boolean // New prop for collapsing functionality
  hiddenCount?: number // Number of hidden items for this type
  onToggleCollapse?: () => void // Handler for toggling collapse
  hasCollapseButton?: boolean // Whether to display the collapse button
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  content,
  messageType,
  sx,
  isCollapsed = false,
  hiddenCount = 0,
  onToggleCollapse,
  hasCollapseButton = false,
}) => {
  return (
    <Box
      sx={{
        padding: 1.5,
        color: (theme) => theme.palette.common.black,
        border: (theme) => `1px solid ${theme.palette[messageType].main}`,
        borderRadius: 1,
        margin: (theme) => theme.spacing(0.5, 0),
        fontSize: (theme) => theme.typography.body2.fontSize,
        background: (theme) => setOpacity(theme.palette[messageType].main, 0.15),
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 700, flex: 1, marginRight: 1 }}>
        {content}
      </Typography>

      {hiddenCount > 0 && onToggleCollapse && (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onToggleCollapse()
          }}
          size="small"
          sx={{
            textTransform: 'none',
            fontSize: '0.75rem',
            padding: 0.5,
            minWidth: 50,
            m: 0,
            color: (theme) => theme.palette[messageType].dark,
            fontWeight: 700,
            backgroundColor: (theme) => setOpacity(theme.palette[messageType].main, 0.1),
            '&:hover': {
              backgroundColor: (theme) => setOpacity(theme.palette[messageType].main, 0.2),
            },
          }}
        >
          {isCollapsed ? `+ ${hiddenCount} autre(s)` : 'Collapse'}
        </Button>
      )}
      {hiddenCount <= 0 && onToggleCollapse && hasCollapseButton && (
        <IconButton size="small" onClick={onToggleCollapse} sx={{ height: '20px' }}>
          <ExpandLess />
        </IconButton>
      )}
    </Box>
  )
}
